@charset "UTF-8";
@font-face {
  font-family: "kmotor";
  src: url("../iconfont/kmotor.eot?bwd7qy");
  src: url("../iconfont/kmotor.eot?bwd7qy#iefix") format("embedded-opentype"), url("../iconfont/kmotor.ttf?bwd7qy") format("truetype"), url("../iconfont/kmotor.woff?bwd7qy") format("woff"), url("../iconfont/kmotor.svg?bwd7qy#kmotor") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "kmotor" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope-o:before {
  content: "\e931";
}

.icon-file-text-o:before {
  content: "\e930";
}

.icon-trash-o:before {
  content: "\e92f";
}

.icon-th-large:before {
  content: "\e921";
}

.icon-bars:before {
  content: "\e922";
}

.icon-navicon:before {
  content: "\e922";
}

.icon-reorder:before {
  content: "\e922";
}

.icon-th-list:before {
  content: "\e923";
}

.icon-file-pdf-o:before {
  content: "\e924";
}

.icon-circle-question-regular:before {
  content: "\e92d";
}

.icon-user-regular:before {
  content: "\e925";
}

.icon-square-check:before {
  content: "\e926";
}

.icon-file-arrow-up:before {
  content: "\e927";
}

.icon-external:before {
  content: "\e928";
}

.icon-plane-solid:before {
  content: "\e929";
}

.icon-circle-xmark:before {
  content: "\e92a";
}

.icon-tick:before {
  content: "\e920";
}

.icon-mobilemenu:before {
  content: "\e91a";
  color: #fff;
}

.icon-addloc:before {
  content: "\e918";
  color: #0082d9;
}

.icon-edit:before {
  content: "\e919";
  color: #0082d9;
}

.icon-skoda:before {
  content: "\e912";
}

.icon-instock:before {
  content: "\e913";
  color: #06b278;
}

.icon-seat:before {
  content: "\e916";
}

.icon-quality:before {
  content: "\e90d";
  color: #0082d9;
}

.icon-Group-41:before {
  content: "\e90e";
  color: #0082d9;
}

.icon-package:before {
  content: "\e90f";
  color: #0082d9;
}

.icon-places:before {
  content: "\e910";
  color: #0082d9;
}

.icon-Path-64:before {
  content: "\e90b";
  color: #0082d9;
}

.icon-Group-10:before {
  content: "\e90c";
  color: #808080;
}

.icon-Path-10:before {
  content: "\e90a";
  color: #fff;
}

.icon-logo:before {
  content: "\e901";
  color: #0082d9;
}

.icon-garage:before {
  content: "\e902";
}

.icon-cart:before {
  content: "\e903";
}

.icon-user:before {
  content: "\e904";
}

.icon-time:before {
  content: "\e905";
  color: #fff;
}

.icon-phone:before {
  content: "\e906";
  color: #fff;
}

.icon-settings:before {
  content: "\e907";
  color: #fff;
}

.icon-find:before {
  content: "\e908";
  color: #fff;
}

.icon-car:before {
  content: "\e909";
  color: #808080;
}

.icon-exclamation-outline:before {
  content: "\e92b";
}

.icon-check-circle:before {
  content: "\e91f";
}

.icon-filtering:before {
  content: "\e935";
}

.icon-home:before {
  content: "\e933";
}

.icon-loop:before {
  content: "\e932";
}

.icon-repeat:before {
  content: "\e932";
}

.icon-player:before {
  content: "\e932";
}

.icon-reload:before {
  content: "\e932";
}

.icon-refresh:before {
  content: "\e932";
}

.icon-update:before {
  content: "\e932";
}

.icon-synchronize:before {
  content: "\e932";
}

.icon-arrows:before {
  content: "\e932";
}

.icon-info-circle:before {
  content: "\e92c";
}

.icon-ban:before {
  content: "\e91e";
}

.icon-search:before {
  content: "\e934";
}

.icon-x-circle:before {
  content: "\e92e";
}

.icon-exclaim:before {
  content: "\e91b";
  color: #ffabab;
}

.icon-info:before {
  content: "\e91c";
  color: #fff5b2;
}

.icon-question:before {
  content: "\e91d";
  color: #7bcaff;
}

.icon-chevron-left:before {
  content: "\e917";
}

.icon-chevron-right:before {
  content: "\e911";
}

.icon-chevron-up:before {
  content: "\e914";
}

.icon-chevron-down:before {
  content: "\e915";
}

.icon-menu:before {
  content: "\e900";
}

/*Text styles*/
.text-primary {
  color: #f3502e;
}

.text-center {
  text-align: center;
}

.text-danger {
  color: darkred;
}

.price-vat {
  color: #909090;
  font-weight: 500;
  font-size: 1.6rem;
}

.light {
  color: #909090;
}

.uppercase {
  text-transform: uppercase;
}

.secondary {
  color: #06B278;
}

.w100 {
  width: 100%;
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
  box-sizing: border-box;
}

.red {
  color: red;
}

/*Margins*/
.mt-0 {
  margin-top: 0rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-5 {
  margin-top: 5rem;
}

/*Mix*/
.worldship h3, .worldship table, .worldship td, .shipping-country h3, .shipping-country table, .shipping-country td {
  text-indent: 0px;
}

.hidden {
  display: none !important;
}

.nobreak {
  white-space: nowrap;
}

* {
  min-width: 0;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-1 14:17:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(10px) translateX(-10px);
    transform: translateY(10px) translateX(-10px);
  }
}
@keyframes slide-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(10px) translateX(-10px);
    transform: translateY(10px) translateX(-10px);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.site-search {
  position: relative;
}
.site-search .btn {
  padding: 8px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.site-search input[type=text] {
  background: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #151617;
  font-size: 1.8rem;
  font-weight: 400;
  border: 0px;
  padding: 14px 25px;
  padding-right: 50px;
  width: 100%;
  box-sizing: border-box;
}
.site-search input[type=text]::placeholder {
  color: #151617;
  opacity: 1;
}
.site-search input[type=text]:focus {
  outline: 0px !important;
}

.site-filter {
  position: relative;
  align-items: center;
  justify-content: center;
}
.site-filter__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-filter__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  object-position: left bottom;
}
@media (max-width: 1024px) {
  .site-filter__bg {
    object-position: right bottom;
  }
}
.site-filter__main {
  padding: 58px 0px;
  width: 100%;
}
.site-filter__main--category {
  background-color: #f3502e;
  padding: 20px 17px;
  padding-top: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.site-filter__header {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -2.5px;
  justify-content: flex-start;
  position: relative;
}
@media (max-width: 576px) {
  .site-filter__header {
    flex-direction: column;
    max-height: 54px;
    overflow: hidden;
  }
}
.site-filter__header--opened {
  max-height: 9999px;
}
.site-filter__header--category {
  align-items: flex-start;
  max-height: auto;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  max-height: 9999px !important;
}
@media (max-width: 576px) {
  .site-filter__header--category {
    align-items: stretch;
  }
}
.site-filter__header-left {
  flex: 0 0 auto;
  position: relative;
  margin-right: 30px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
}
@media (max-width: 576px) {
  .site-filter__header-left {
    margin-right: 0px;
    flex: 1 1 auto;
  }
}
.site-filter__header-left:after {
  content: "\e915";
  font-family: "kmotor";
  position: absolute;
  top: 10px;
  color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2) !important;
  height: 30px;
  width: 30px;
  right: 0px;
  text-align: center;
  font-size: 2rem;
  line-height: 30px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .site-filter__header-left:after {
    display: none;
  }
}
.site-filter__header-left:hover:after {
  border: 2px solid white;
}
@media (max-width: 768px) {
  .site-filter__header-left--show::after {
    display: block;
  }
}
.site-filter__header-right {
  flex: 1 1 auto;
}
.site-filter__header-right .site-filter__header-text {
  display: none;
}
.site-filter__header-right .site-filter__header-text--active {
  display: block;
  order: -1;
}
.site-filter__header-right .site-filter__header-text p {
  color: white;
  margin: 20px 0px;
}
@media (max-width: 576px) {
  .site-filter__header-right .site-filter__header-text p {
    margin: 0;
  }
}
.site-filter__header-right .site-filter__header-text p.infotext {
  display: block;
}
@media (max-width: 768px) {
  .site-filter__header-right .site-filter__header-text p.infotext {
    display: none;
  }
}
.site-filter__header-right .site-filter__header-text p.devicetext {
  display: none;
}
@media (max-width: 768px) {
  .site-filter__header-right .site-filter__header-text p.devicetext {
    display: block;
  }
}
@media (max-width: 768px) {
  .site-filter__header-right--show .site-filter__header-text p.infotext {
    display: block;
  }
}
@media (max-width: 768px) {
  .site-filter__header-right--show .site-filter__header-text p.devicetext {
    display: none;
  }
}
.site-filter__header-item {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-top-right: 5px;
  -moz-border-radius-bottom-right: 0px;
  -moz-border-radius-bottom-left: 0px;
  -moz-border-radius-top-left: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: #f3502e;
  color: white;
  font-size: 2rem;
  padding: 15px 27px;
  margin: 0px 2.5px;
  cursor: pointer;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .site-filter__header-item {
    display: none;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-top-left-radius: 0px;
    -moz-border-radius-top-right: 0px;
    -moz-border-radius-bottom-right: 0px;
    -moz-border-radius-bottom-left: 0px;
    -moz-border-radius-top-left: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
}
.site-filter__header-item i {
  margin-right: 13px;
}
.site-filter__header-item i:before {
  color: rgba(255, 255, 255, 0.4) !important;
}
.site-filter__header-item--active {
  background: white;
  color: #151617;
  cursor: no-drop;
}
@media (max-width: 576px) {
  .site-filter__header-item--active {
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-radius-top-right: 5px;
    -moz-border-radius-bottom-right: 0px;
    -moz-border-radius-bottom-left: 0px;
    -moz-border-radius-top-left: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: block;
    background: #f3502e;
    color: white;
    cursor: pointer;
  }
}
.site-filter__header-item--active i:before {
  color: rgba(0, 0, 0, 0.4) !important;
}
@media (max-width: 576px) {
  .site-filter__header-item--active i:before {
    color: rgba(255, 255, 255, 0.4) !important;
  }
}
.site-filter__header-item--category {
  display: none;
  padding: 15px 0px;
  padding-right: 40px;
  max-height: 9999px !important;
}
@media (max-width: 576px) {
  .site-filter__header-item--category {
    padding: 15px 0px;
    padding-right: 40px;
  }
}
.site-filter__header-mobile {
  display: none;
}
@media (max-width: 576px) {
  .site-filter__header-mobile {
    display: block;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  .site-filter__header-mobile:after {
    content: "\e915";
    font-family: "kmotor";
    position: absolute;
    top: 10px;
    color: white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2) !important;
    height: 30px;
    width: 30px;
    right: 0px;
    text-align: center;
    font-size: 2rem;
    line-height: 30px;
    cursor: pointer;
  }
}
.site-filter__header .site-filter__header-item--active.site-filter__header-item--category {
  display: block;
  background: #f3502e;
  color: white;
  cursor: pointer;
  order: -1;
}
.site-filter__header .site-filter__header-item--active.site-filter__header-item--category i:before {
  color: rgba(255, 255, 255, 0.4) !important;
}
.site-filter__header .site-filter__header-item--show.site-filter__header-item--active {
  order: -1;
}
@media (max-width: 576px) {
  .site-filter__header .site-filter__header-item--show {
    display: inherit;
  }
}
.site-filter__header .site-filter__header-item--show.site-filter__header-item--category {
  display: inherit;
}
.site-filter__content {
  background: white;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-top-right: 0px;
  -moz-border-radius-bottom-right: 5px;
  -moz-border-radius-bottom-left: 5px;
  -moz-border-radius-top-left: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  overflow: hidden;
}
.site-filter__content .select2-container .select2-selection--single {
  height: 56px;
  background: #EFF1F3;
}
.site-filter__content .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 54px;
  line-height: 56px;
}
.site-filter__content .select2-container--default .select2-selection--single {
  border: 0px;
}
.site-filter__content .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 54px;
}
.site-filter__content .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 50px;
}
.site-filter__content--category {
  background: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 5px;
}
@media (max-width: 768px) {
  .site-filter__content--category {
    max-height: 0px;
    overflow: hidden;
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .site-filter__content--category.site-filter__content--show {
    max-height: 9999px;
    padding: 5px;
  }
}
@media (max-width: 576px) {
  .site-filter__content--category.site-filter__content--show {
    margin-top: 20px;
  }
}
.site-filter__content--category .site-filter__content-box {
  padding: 5px;
  flex-grow: 1;
}
@media (max-width: 768px) {
  .site-filter__content--category .site-filter__content-box {
    flex: 1 1 auto;
  }
}
.site-filter__content--category .site-filter__content-box--button {
  padding-left: 10px;
  flex: 0 0 60px;
}
@media (max-width: 1024px) {
  .site-filter__content--category .site-filter__content-box--button {
    padding: 0;
    flex: 1 1 auto;
  }
}
.site-filter__content--category .site-filter__content-box--smallbutton {
  flex-grow: 0;
}
.site-filter__content--category .btn {
  width: auto;
  background-color: #f3502e;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .site-filter__content--category .btn {
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
  }
}
.site-filter__content--category .btn--noflex {
  display: inline-block !important;
}
.site-filter__content--category .btn .icon {
  display: block !important;
  left: 12px !important;
}
.site-filter__content--category .btn .caption {
  display: none;
}
@media (max-width: 1024px) {
  .site-filter__content--category .btn .caption {
    display: inline-block;
    padding-left: 30px;
  }
}
.site-filter__content--category .site-filter__content-number {
  left: 15px;
  top: 16.5px;
}
.site-filter__content--category .select2-container--default .select2-selection--single,
.site-filter__content--category .select2-container--default.select2-container--disabled .select2-selection--single {
  background: white;
  border: white;
}
.site-filter__content--category input {
  background: white;
}
.site-filter__content-item {
  box-sizing: border-box;
  display: none;
}
.site-filter__content-item--closed {
  display: none;
}
.site-filter__content-item--opened {
  display: block;
}
.site-filter__content-item--opened form {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .site-filter__content-item--opened form {
    display: block;
  }
}
.site-filter__content-number {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  text-align: center;
  line-height: 31px;
  font-size: 1.8rem;
  border: 1px solid #f3502e;
  color: #f3502e;
  position: absolute;
  top: 32px;
  z-index: 2;
  left: 28px;
  box-sizing: border-box;
}
.site-filter__content-number--passed {
  background: #f3502e;
  color: white;
}
@media (max-width: 1024px) {
  .site-filter__content-number {
    top: 22px;
    left: 18px;
  }
}
.site-filter__content-input {
  background-color: #EFF1F3;
  width: 100%;
  height: 56px;
  border: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 1.8rem;
  padding: 0px 15px;
  box-sizing: border-box;
}
.site-filter__content-input:focus {
  outline: none;
}
.site-filter__content-box {
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
@media (max-width: 1024px) {
  .site-filter__content-box {
    padding: 10px;
  }
}
@media (max-width: 576px) {
  .site-filter__content-box {
    width: 100% !important;
  }
}
.site-filter__content-box--company {
  width: 22.5%;
}
@media (max-width: 1024px) {
  .site-filter__content-box--company {
    width: 33.333%;
  }
}
.site-filter__content-box--serial {
  width: 35%;
}
@media (max-width: 1024px) {
  .site-filter__content-box--serial {
    width: 33.333%;
  }
}
.site-filter__content-box--type {
  width: 22.5%;
}
@media (max-width: 1024px) {
  .site-filter__content-box--type {
    width: 33.333%;
  }
}
.site-filter__content-box--kbacompany {
  width: 40%;
}
.site-filter__content-box--kbaserial {
  width: 40%;
}
.site-filter__content-box--motornumber {
  width: 27%;
}
@media (max-width: 1024px) {
  .site-filter__content-box--motornumber {
    width: 100%;
  }
}
.site-filter__content-box--kba {
  flex: 1 1 auto;
}
.site-filter__content-box--codeand {
  font-size: 2.5rem;
  text-align: center;
}
.site-filter__content-box--motorseparator span {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  text-transform: uppercase;
}
.site-filter__content-box--motorseparator span.sign {
  font-size: 2.2rem;
}
@media (max-width: 1024px) {
  .site-filter__content-box--motorseparator {
    width: 100%;
  }
}
.site-filter__content-box--motormanu {
  width: 25%;
}
@media (max-width: 1024px) {
  .site-filter__content-box--motormanu {
    width: 50%;
  }
}
.site-filter__content-box--motorserial {
  width: 25%;
}
@media (max-width: 1024px) {
  .site-filter__content-box--motorserial {
    width: 50%;
  }
}
.site-filter__content-box--button {
  width: 20%;
  padding: 20px;
}
.site-filter__content-box--button .btn {
  position: relative;
}
.site-filter__content-box--button .btn .icon {
  position: absolute;
  left: 15px;
  display: none;
}
@media (max-width: 1024px) {
  .site-filter__content-box--button {
    width: 100%;
    padding: 10px;
  }
}
.site-filter__content-box--smallbutton {
  width: auto;
  flex: 1 1 auto;
}
@media (max-width: 1024px) {
  .site-filter__content-box--smallbutton {
    width: 100%;
    padding: 10px;
  }
}
.site-filter__content-box--smallbutton .btn {
  position: relative;
}
.site-filter__content-box--smallbutton .icon {
  position: absolute;
  left: 15px;
  display: none;
}
.site-filter__minimizer {
  display: none;
  padding: 15px;
  text-align: center;
  background: #EFF1F3;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-top-right: 0px;
  -moz-border-radius-bottom-right: 5px;
  -moz-border-radius-bottom-left: 5px;
  -moz-border-radius-top-left: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  cursor: pointer;
}
@media (max-width: 768px) {
  .site-filter__minimizer {
    display: block;
  }
}
.site-filter__minimizer-item span {
  text-decoration: underline;
  color: black;
  font-size: 1.8rem;
}
.site-filter__minimizer-item:after {
  text-decoration: none;
  content: "\e915";
  font-size: 2rem;
  font-family: "kmotor";
  font-weight: 600;
  position: absolute;
}
.site-filter__minimizer-item--show {
  display: block;
}
.site-filter__minimizer-item--hide {
  display: none;
}
.site-filter__minimizer-item--hide:after {
  content: "\e914";
}
.site-filter__minimizer--opened .site-filter__minimizer-item--show {
  display: none;
}
.site-filter__minimizer--opened .site-filter__minimizer-item--hide {
  display: block;
}
.site-filter select:focus {
  border: 0px !important;
}

.site-cookies {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #f3502e;
  color: white;
  padding: 10px 0px;
  box-sizing: border-box;
  z-index: 10001;
}
.site-cookies__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.site-cookies__right {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.site-cookies__right a, .site-cookies__right button {
  margin: 5px;
  cursor: pointer;
}
.site-cookies a {
  color: white;
  text-decoration: underline;
}

.blog-feed__slider {
  margin: 0 -1.5rem;
  padding-top: 6rem;
  overflow: hidden;
  padding-bottom: 40px;
  margin-bottom: 0px !important;
}
@media (max-width: 768px) {
  .blog-feed__slider {
    margin: 0px -15px;
  }
}
@media (max-width: 576px) {
  .blog-feed__slider {
    padding: 0px -5px;
  }
}
.blog-feed__item {
  padding: 0px 15px;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  /*320*/
  box-sizing: border-box;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
}
.blog-feed__item-heading {
  color: white;
  text-transform: uppercase;
  font-weight: 200;
  margin-top: 0px;
  font-size: 5rem;
  z-index: 2;
  position: relative;
  max-height: 25vh;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-feed__item .btn {
  position: relative;
  z-index: 2;
}
.blog-feed__item-content {
  /*padding:35px 40px;*/
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  /*min-height: 416px;*/
  height: 100%;
  box-sizing: border-box;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
}
.blog-feed__item-link {
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
.blog-feed__item-image {
  opacity: 0.6;
  position: absolute;
  right: -20%;
  bottom: -25px;
  z-index: 1;
}
.blog-feed__item-bg {
  width: 100%;
  height: 100%;
}
.blog-feed__item--bigger {
  width: 680px;
}
.blog-feed__item--bigger .blog-feed__item-image {
  bottom: 20px;
  right: 20px;
}
.blog-feed__item--primary .blog-feed__item-content {
  background-color: #772615;
}
.blog-feed__item--secondary .blog-feed__item-content {
  background-color: #06B278;
}
.blog-feed__item--third .blog-feed__item-content {
  background-color: #F3502E;
}
.blog-feed .slick-dots {
  bottom: 10px;
}

.blog {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  gap: 30px;
  margin-bottom: 4.2rem;
}
@media (max-width: 1024px) {
  .blog {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .blog {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (max-width: 450px) {
  .blog {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
.blog__post {
  box-sizing: border-box;
}
.blog__post-inner {
  padding: 15px;
  box-sizing: border-box;
  background: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  height: 100%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.blog__post-inner .btn {
  margin-top: auto;
}
.blog__post-title {
  font-size: 2rem;
  margin-top: 5px;
  margin-bottom: 10px;
}
.blog__post p {
  margin-top: 0px;
}

.site-breadcrumbs {
  border-top: 1px solid #EFF1F3;
  border-bottom: 1px solid #EFF1F3;
  padding: 10px 0px;
}
.site-breadcrumbs__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .site-breadcrumbs__list-item {
    display: none;
  }
}
.site-breadcrumbs__list-item:after {
  content: "/";
  padding: 0px 10px;
}
.site-breadcrumbs__list-item a {
  color: #151617;
}
.site-breadcrumbs__list-item--active:after {
  content: "";
}
@media (max-width: 576px) {
  .site-breadcrumbs__list-item--active {
    font-size: 0px;
  }
}
@media (max-width: 576px) {
  .site-breadcrumbs__list-item--mobile {
    display: block;
  }
  .site-breadcrumbs__list-item--mobile:after {
    content: "";
  }
  .site-breadcrumbs__list-item--mobile::before {
    content: "<";
  }
}

.all-categories {
  overflow: hidden;
  max-width: 100%;
}
.all-categories__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
@media (max-width: 768px) {
  .all-categories__container {
    margin: 0 -7px;
  }
}
.all-categories__item {
  width: 25%;
  padding: 13px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .all-categories__item {
    padding: 7px;
  }
}
@media (max-width: 450px) {
  .all-categories__item {
    width: 50%;
  }
}
.all-categories__item--homepage {
  width: 16.667%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  display: none;
}
@media (max-width: 768px) {
  .all-categories__item--homepage {
    width: 25%;
  }
}
@media (max-width: 450px) {
  .all-categories__item--homepage {
    width: 50%;
  }
}
.all-categories__item--homepage:nth-child(1), .all-categories__item--homepage:nth-child(2), .all-categories__item--homepage:nth-child(3), .all-categories__item--homepage:nth-child(4), .all-categories__item--homepage:nth-child(5), .all-categories__item--homepage:nth-child(6) {
  display: block;
}
.all-categories__item--homepage:nth-child(6) .all-categories__item-more {
  display: block;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #EFF1F3;
  position: absolute;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.8rem;
  flex-direction: column;
  color: #f3502e;
}
.all-categories__item--homepage:nth-child(6) .all-categories__item-more .icon {
  height: 13.4rem;
  font-size: 3rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
.all-categories__item--homepage.all-categories__item--show {
  display: block !important;
}
.all-categories__item-content {
  background-color: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  padding: 9px 14px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
@media (max-width: 768px) {
  .all-categories__item-content {
    flex-direction: column;
    justify-content: center;
  }
}
.all-categories__item-content--homepage {
  flex-direction: column;
  justify-content: center;
  padding: 25px;
}
.all-categories__item-image {
  max-height: 6rem;
}
.all-categories__item-image--homepage {
  max-height: 13.4rem;
}
.all-categories__item-text {
  text-decoration: none;
  margin-left: 1rem;
}
@media (max-width: 768px) {
  .all-categories__item-text {
    text-align: center;
    margin-left: 0;
  }
}
.all-categories__item-text--homepage {
  text-align: center;
  margin-left: 0;
  font-size: 1.8rem;
}
.all-categories__item-text a {
  text-decoration: none;
  color: #151617;
}
.all-categories__item-more {
  display: none;
}

.usedin__buttons {
  padding: 15px 9px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
}
.usedin__buttons-item {
  flex: 1 1 auto;
  margin: 0px 15px;
  text-align: center;
  background: #b2b2b2;
  color: white;
}
.usedin__buttons-item--active {
  background: #f3502e;
}
.usedin__blank {
  padding: 0px 24px;
  margin: 0px;
}
.usedin__main {
  margin: 0px;
  padding: 0px;
  display: none;
}
.usedin__main--opened {
  display: block;
}
.usedin__main-item {
  list-style: none;
  position: relative;
}
.usedin__main-item:last-child .usedin__main-link {
  border-bottom: 0px !important;
}
.usedin__main-link {
  display: block;
  padding: 15px 24px;
  border-bottom: 1px solid white;
  cursor: pointer;
  font-size: 1.8rem;
}
.usedin__main-link:after {
  content: "\e911";
  font-family: "kmotor";
  font-size: 2rem;
  position: absolute;
  right: 15px;
  color: #f3502e;
  top: 13px;
}
.usedin__main-link.active::after {
  content: "\e915";
  font-family: "kmotor";
}
.usedin__submenu {
  margin: 0px;
  padding: 0px;
  max-height: 0;
  overflow: hidden;
  -moz-transition: 0.3s all linear;
  -o-transition: 0.3s all linear;
  -webkit-transition: 0.3s all linear;
  transition: 0.3s all linear;
}
.usedin__submenu-item {
  list-style: none;
  position: relative;
  padding: 15px 15px 4px 55px;
  border-bottom: 1px solid white;
}
.usedin__submenu-link {
  text-decoration: underline;
  font-size: 1.8rem;
  cursor: pointer;
  display: block;
  padding-bottom: 11px;
}
.usedin__submenu-link::before {
  content: "\e911";
  font-family: "kmotor";
  position: absolute;
  left: 25px;
  color: #f3502e;
  top: 16px;
}
.usedin__submenu-link.active::before {
  content: "\e915";
  font-family: "kmotor";
}
.usedin__submenu:last-child .usedin__submenu-item:last-child {
  border-bottom: 0px !important;
}
.usedin__model {
  margin: 0px;
  padding: 0px;
  max-height: 0;
  overflow: hidden;
  -moz-transition: 0.3s all linear;
  -o-transition: 0.3s all linear;
  -webkit-transition: 0.3s all linear;
  transition: 0.3s all linear;
}
.usedin__model-item {
  list-style: none;
  padding-bottom: 11px;
}
.usedin__model-item-motor {
  list-style: none;
  padding: 15px 15px 4px 55px;
}
.usedin__model:last-child .usedin__model-item-motor:last-child {
  border-bottom: 1px solid white;
}
.usedin__model-properties {
  margin-top: -5px;
}
.usedin__model-properties span {
  display: inline-block;
  padding: 5px 15px;
  position: relative;
}
@media (max-width: 1024px) {
  .usedin__model-properties span {
    padding: 5px;
  }
}
.usedin__model-properties span:after {
  content: "|";
  position: absolute;
  right: 0px;
}
.usedin__model-properties span:first-child {
  padding-left: 0px;
}
.usedin__model-properties span:last-child::after {
  display: none;
}
.usedin__model-linked {
  margin-top: 15px;
}
.usedin__model-spacer {
  display: block;
  height: 15px;
}

.togglelink {
  cursor: pointer;
}

.toggle--open {
  max-height: 9999px !important;
}

.product-item {
  width: 25%;
  box-sizing: border-box;
  padding: 1.5rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
}
.product-item--slider {
  width: auto;
  height: 100%;
}
.product-item--slider .brand-logo {
  max-width: 180px;
  max-height: 40px;
  padding-bottom: 10px;
}
.product-item--slider__text {
  padding-top: 10px;
}
.product-item--slider .product-detail__buy-form {
  background-color: #eff1f3;
}
.product-item--slider .product-detail__buy-form input {
  background-color: transparent;
}
.product-item--gallery {
  width: 33.333%;
}
.product-item--gallery__text {
  padding-top: 10px;
}
.product-item--gallery .brand-logo {
  padding-bottom: 10px;
}
@media (max-width: 768px) {
  .product-item--gallery {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .product-item--gallery {
    width: 100%;
  }
}
.product-item__image {
  position: relative;
  height: 250px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-item__image img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}
.product-item__image img.pictogram {
  width: auto;
  position: absolute;
  top: 20px;
  left: 5px;
}
.product-item__link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}
.product-item__badges {
  position: absolute;
  bottom: 10px;
  left: 0px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.product-item__text {
  flex: 1 1 auto;
}
.product-item__name {
  text-decoration: none;
  font-size: 2rem;
  color: #151617;
  display: block;
  margin-bottom: 0rem;
  height: 5.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-item__vehicles {
  color: #b2b2b2;
  height: 3.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-item__properties-header {
  color: #f3502e;
  border-bottom: 1px solid #EFF1F3;
  text-align: center;
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
}
.product-item__properties-header span {
  display: inline-block;
  background-color: white;
  padding: 5px 10px;
  position: absolute;
  left: 50%;
  top: -16px;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  cursor: pointer;
  white-space: nowrap;
}
.product-item__properties-header span:after {
  content: "\e915";
  font-family: "kmotor";
  font-size: 1.2rem;
  margin-left: 5px;
}
.product-item__properties-header span:nth-child(2) {
  display: none;
}
.product-item__properties-header--open span {
  display: none;
}
.product-item__properties-header--open span::after {
  content: "\e914";
}
.product-item__properties-header--open span:nth-child(2) {
  display: inline-block;
}
.product-item__properties-content table tr.class-collapse {
  display: none;
}
.product-item__properties-content--open {
  margin-bottom: 2rem;
  max-height: 9999px;
}
.product-item__properties-content--open table tr.class-collapse {
  display: table-row;
}
.product-item__buy {
  margin-top: auto;
}
.product-item__buy-button {
  width: 100%;
  position: relative;
  padding: 15px 45px;
}
.product-item__bottom {
  margin-top: auto;
}
.product-item__pricebox {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 15px 0px;
}
.product-item__pricebox-left, .product-item__pricebox-right {
  width: 50%;
  margin: 0px;
}
.product-item__pricebox-left .product-detail__buy-stock, .product-item__pricebox-right .product-detail__buy-stock {
  margin-bottom: 0px;
  width: auto;
}
.product-item__pricebox-right > div {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: flex-end;
}
.product-item__pricebox-main {
  font-size: 2.2rem;
  color: #f3502e;
  display: block;
}
.product-item__pricebox-vat {
  font-size: 1.4rem;
  color: #151617;
}
.product-item__pricebox-right {
  padding-left: 10px;
  box-sizing: border-box;
}

.product-line__item {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin-bottom: 4.2rem;
  align-items: flex-start;
}
@media (max-width: 1200px) {
  .product-line__item {
    flex-wrap: wrap;
  }
}
.product-line__item-left {
  flex: 1 1 auto;
}
.product-line__item-right {
  flex: 0 0 320px;
  background: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
@media (max-width: 1200px) {
  .product-line__item-right {
    flex: 1 1 100%;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .product-line__item-right .product-detail__price-item {
    flex: 1 1 auto;
    border-bottom: 0px;
  }
}
@media (max-width: 1200px) {
  .product-line__item-right .product-detail__price-item--pricestock {
    border-top: 1px solid white;
    flex: 1 0 275px;
    box-sizing: content-box;
  }
}
@media (max-width: 576px) {
  .product-line__item-right .product-detail__price-item--pricestock {
    flex: 1 1 auto;
  }
}
.product-line__item .brand-logo {
  max-width: 180px;
  max-height: 40px;
}
.product-line__heading {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .product-line__heading img {
    margin-bottom: 10px;
  }
}
.product-line__heading h3 {
  margin: 0px 2rem;
}
@media (max-width: 576px) {
  .product-line__heading h3 {
    margin: 0px;
    flex: 0 0 100%;
    margin-bottom: 10px;
  }
}
.product-line__heading h3 a {
  text-decoration: none;
}
.product-line__heading .badge:first-of-type {
  margin-left: 0px;
}
.product-line__content {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding-right: 20px;
  margin-top: 20px;
}
@media (max-width: 1200px) {
  .product-line__content {
    padding-right: 0px;
    padding-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .product-line__content {
    flex-direction: column;
  }
}
.product-line__image {
  flex: 0 0 33.333%;
  padding: 0px 20px;
  padding-left: 0px;
  box-sizing: border-box;
  position: relative;
}
.product-line__image--full {
  flex: 0 0 50%;
}
@media (max-width: 576px) {
  .product-line__image {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 25px;
    padding-right: 0px;
  }
}
.product-line__image img {
  max-height: 30vh;
}
@media (max-width: 768px) {
  .product-line__image img {
    max-height: auto;
  }
}
@media (max-width: 576px) {
  .product-line__image img {
    max-height: 30vh;
  }
}
.product-line__image img.pictogram {
  position: absolute;
  left: 5px;
  top: 25px;
}
.product-line__allproperties {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex: 0 0 66.666%;
}
@media (max-width: 768px) {
  .product-line__allproperties {
    flex-wrap: wrap;
  }
}
@media (max-width: 576px) {
  .product-line__allproperties {
    flex: 1 1 100%;
  }
}
.product-line__allproperties--full {
  display: block;
  flex: 0 0 50%;
}
.product-line__allproperties .product-detail__data tr > td:first-child {
  width: auto;
}
.product-line__allproperties .product-detail__data tr > td:last-child {
  text-align: right;
  overflow-wrap: break-word;
  word-break: break-word;
}
.product-line__allproperties h4 {
  margin-top: 0px;
  margin-bottom: 10px;
}
.product-line__basic {
  flex: 0 0 50%;
  padding: 0px 20px;
  box-sizing: border-box;
  position: relative;
}
@media (max-width: 768px) {
  .product-line__basic {
    flex: 0 0 100%;
  }
}
.product-line__showall {
  color: #f3502e;
  margin-top: 10px;
  margin-bottom: 4rem;
  padding: 0px 20px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.product-line__showall::after {
  content: "";
  border-bottom: 1px solid #f3502e;
  width: calc(100% - 40px);
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}
.product-line__showall--nopadding {
  padding: 0px;
}
.product-line__showall--nopadding::after {
  width: 100%;
  left: 0px;
}
.product-line__showall span {
  display: inline-block;
  position: relative;
  background-color: #fff;
  padding: 5px 10px;
  z-index: 10;
}
.product-line__showall span::after {
  content: "\e915";
  font-family: "kmotor";
  margin-left: 8px;
  vertical-align: -2px;
}
.product-line__showall span:nth-child(2) {
  display: none;
}
.product-line__showall--open span {
  display: none;
}
.product-line__showall--open span::after {
  content: "\e914";
}
.product-line__showall--open span:nth-child(2) {
  display: inline-block;
}
.product-line__properties {
  flex: 0 0 50%;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .product-line__properties {
    flex: 0 0 100%;
  }
}
.product-line__properties--full {
  width: 100%;
  flex: 0 0 100%;
  padding: 0px;
  margin-top: 0px;
}
.product-line__properties-outer {
  flex: 0 0 50%;
  padding: 0px 20px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .product-line__properties-outer {
    margin-top: 15px;
    flex: 0 0 100%;
  }
}
.product-line__properties table tr.class-collapse {
  display: none;
}
.product-line__properties--open table tr.class-collapse {
  display: table-row;
}

.product-smallest {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 30px 0px;
  border-bottom: 1px solid #EFF1F3;
  align-items: center;
}
@media (max-width: 768px) {
  .product-smallest {
    flex-wrap: wrap;
  }
}
.product-smallest__name {
  flex: 0 0 25%;
  padding-right: 30px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .product-smallest__name {
    flex: 1 1 100%;
    padding-right: 0px;
    margin-bottom: 20px;
  }
}
.product-smallest__name-heading {
  font-size: 2.2rem;
  margin: 0px !important;
  margin-bottom: 5px;
}
.product-smallest__name-heading a {
  text-decoration: none;
}
.product-smallest__properties {
  flex: 1 1 auto;
}
@media (max-width: 768px) {
  .product-smallest__properties {
    flex: 1 1 100%;
  }
}
.product-smallest__properties-switch {
  color: #f3502e;
  cursor: pointer;
  display: inline-block;
}
.product-smallest__properties-switch span {
  text-decoration: underline;
  font-size: 2rem;
}
.product-smallest__properties-list {
  max-height: 0px;
  overflow: hidden;
}
.product-smallest__properties-list--open {
  max-height: 9999px;
}
.product-smallest__properties-item::after {
  content: "|";
  color: #EFF1F3;
}
.product-smallest__properties-item:last-of-type::after {
  content: "";
}
.product-smallest__properties-name {
  color: #b2b2b2;
}
.product-smallest__properties .product-detail__buy-stock {
  margin-bottom: 0px;
}
.product-smallest__price {
  flex: 0 0 20%;
  padding-left: 40px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .product-smallest__price {
    flex: 1 1 50%;
    padding-left: 0px;
    padding: 15px 0px;
  }
}
@media (max-width: 576px) {
  .product-smallest__price {
    flex: 1 1 100%;
  }
}
.product-smallest__price-main {
  font-size: 3rem;
  color: #f3502e;
  display: block;
}
.product-smallest__price-dph {
  font-size: 1.6rem;
}
.product-smallest__buy {
  padding-left: 30px;
  flex: 0 0 285px;
}
@media (max-width: 768px) {
  .product-smallest__buy {
    flex: 1 1 50%;
    padding-left: 0px;
  }
}
@media (max-width: 576px) {
  .product-smallest__buy {
    flex: 1 1 100%;
  }
}
.product-smallest__buy .product-detail__buy-form {
  background-color: #EFF1F3;
}
.product-smallest__buy .product-detail__buy-input input {
  background-color: transparent;
}

.product-list {
  position: relative;
}
.product-list--gallery {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.product-list.waiting:before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
.product-list .brand-logo {
  max-width: 180px;
  max-height: 40px;
}
.product-list .product-detail__buy-form {
  background-color: #eff1f3;
}
.product-list .product-detail__buy-form input {
  background-color: transparent;
}

.product-nav {
  border-bottom: 1px solid #EFF1F3;
  width: 100%;
  position: relative;
  height: 68px;
  overflow-y: hidden;
  margin-bottom: 20px;
}
.product-nav .container {
  position: relative;
}
.product-nav .container:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 4rem;
  color: #f3502e;
  background: rgba(255, 255, 255, 0.6);
  display: block;
  height: 40px;
  width: 15px;
}
.product-nav__container {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 50px;
}
.product-nav__item {
  font-size: 2.2rem;
  border-bottom: 2px solid white;
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  margin: 0 2rem;
}
@media (max-width: 576px) {
  .product-nav__item {
    margin: 0 1.5rem;
  }
}
.product-nav__item a {
  padding: 2rem 0rem;
  text-decoration: none;
  display: inline-block;
  color: inherit;
  white-space: nowrap;
}
.product-nav__item:hover {
  color: #f3502e;
}
.product-nav__item--active {
  color: #f3502e;
  border-bottom: 2px solid #f3502e;
}
.product-nav__item-stock {
  display: inline-block;
  position: relative;
  color: white;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.product-nav__item-stock--instock {
  background: #06B278;
}
.product-nav__item-stock--outstock {
  background: #F3502E;
}

.customer-card {
  border: 1px solid #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 18px 24px;
  box-sizing: border-box;
  margin-bottom: 5rem;
}
@media (max-width: 576px) {
  .customer-card {
    margin-bottom: 2rem;
  }
}
.customer-card__name {
  font-size: 2.2rem;
  margin-bottom: 10px;
  display: inline-block;
}
.customer-card__label {
  font-size: 2.2rem;
  margin-bottom: 10px;
  margin-top: 15px;
  display: inline-block;
}
.customer-card__label:first-child {
  margin-top: 0px;
}
.customer-card__line {
  margin-top: 15px;
}
.customer-card__line:first-child {
  margin-top: 0px;
}
.customer-card__tool {
  margin-top: -3.8rem;
  margin-bottom: 1rem;
}
.customer-card__tool a, .customer-card__tool span {
  color: #f3502e;
  cursor: pointer;
  margin: 5px;
  margin-right: 10px;
  text-decoration: none;
}
.customer-card__tool a .icon, .customer-card__tool span .icon {
  margin-right: 5px;
  vertical-align: -2px;
}
.customer-card__tool a .icon:before, .customer-card__tool span .icon:before {
  color: #f3502e;
}
.customer-card__tool-text {
  text-decoration: underline;
}
.customer-card .site-form__section-content::before {
  display: none;
}

.homepage-bookmark {
  padding: 60px 0px;
}
.homepage-bookmark__header {
  border-bottom: 1px solid #EFF1F3;
}
.homepage-bookmark__header-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0px;
}
.homepage-bookmark__header-item {
  list-style: none;
  font-size: 2.2rem;
  text-align: center;
  padding: 2rem 0rem;
  margin: 0rem 2rem;
  cursor: pointer;
}
.homepage-bookmark__header-item:hover {
  color: #f3502e;
}
.homepage-bookmark__header-item--active {
  border-bottom: 2px solid #f3502e;
  color: #f3502e;
}
.homepage-bookmark__body-item {
  max-height: 0;
  overflow: hidden;
}
.homepage-bookmark__body-item--active {
  max-height: 9999px;
}

.category-sidebar {
  flex: 0 0 30%;
  max-width: 320px;
}
@media (max-width: 1024px) {
  .category-sidebar {
    position: fixed;
    transform: translate(-100%, 0);
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    z-index: 21;
    left: 0;
    top: 150px;
    height: calc(100vh - 150px);
    width: 100%;
    background: white;
    -moz-transition: transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    -webkit-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
  }
}
@media (max-width: 768px) {
  .category-sidebar {
    top: 80px;
    height: calc(100vh - 80px);
  }
}
@media (max-width: 576px) {
  .category-sidebar {
    max-width: 100%;
  }
}
@media (max-width: 1024px) {
  .category-sidebar--active {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
  }
}
.category-sidebar__inner {
  background: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
@media (max-width: 1024px) {
  .category-sidebar__inner {
    background: white;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 5rem;
    box-sizing: border-box;
  }
}
.category-sidebar__close {
  padding: 10px 18px;
  font-size: 2.5rem;
  display: none;
}
@media (max-width: 1024px) {
  .category-sidebar__close {
    display: inline-block;
    width: 100%;
    text-align: right;
    box-sizing: border-box;
  }
}
.category-sidebar__close span {
  vertical-align: -2px;
  font-size: 3.2rem;
}
.category-sidebar__form {
  background: white;
  padding: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.category-sidebar__form-input {
  border: 0px;
  padding: 0px 17px;
  flex: 1 1 auto;
}
.category-sidebar__form-input::placeholder {
  opacity: 1;
}
.category-sidebar__form-input:focus {
  outline: 0;
}
.category-sidebar__form-btn {
  background: #f3502e;
  color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 0px;
  font-size: 1.9rem;
  padding: 10px;
}
.category-sidebar__heading {
  display: block;
  padding: 13px 24px;
  border-bottom: 2px solid white;
  font-size: 2.2rem;
  position: relative;
  cursor: pointer;
}
.category-sidebar__heading:after {
  content: "\e914";
  font-family: "kmotor";
  font-size: 18px;
  position: absolute;
  right: 24px;
  top: 17px;
}
.category-sidebar__heading--noactive {
  border-bottom: 0px;
}
.category-sidebar__heading--noactive:after {
  content: "\e915";
}
.category-sidebar__content {
  padding: 13px 24px;
  max-height: 9999px;
  position: relative;
}
.category-sidebar__content--noactive {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}
.category-sidebar__content--tags .site-form__line {
  padding-bottom: 1rem;
}
.category-sidebar__content--select .site-form__line {
  padding-bottom: 0px;
}
.category-sidebar__content .site-form__line {
  z-index: 22;
}
.category-sidebar__switchtree {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.category-sidebar__switchtree-link {
  flex: 1 1 auto;
  text-align: center;
}
.category-sidebar__switchtree-link--active {
  color: #151617;
  text-decoration: none;
}
.category-sidebar__switchtree-link--plain {
  color: #f3502e;
  text-decoration: underline;
}
.category-sidebar__switchtree-sign {
  padding: 0px 8px;
}
.category-sidebar__list {
  margin: 0px;
  padding: 0px;
  margin-bottom: 2rem;
}
.category-sidebar__item {
  list-style: none;
  position: relative;
  margin-bottom: 10px;
}
.category-sidebar__item-haschild {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.category-sidebar__item-plain {
  margin-left: 19px;
}
.category-sidebar__item-switch {
  position: relative;
  color: #f3502e;
  font-size: 2.6rem;
  font-weight: 600;
  cursor: pointer;
  line-height: 18px;
  margin-right: 8px;
  align-self: flex-start;
  flex: 0 0 11px;
}
.category-sidebar__item-switch--opened {
  font-size: 0px;
}
.category-sidebar__item-switch--opened:after {
  content: "-";
  font-size: 2.6rem;
  width: 11px;
  display: inline-block;
  padding-left: 1px;
  box-sizing: border-box;
  line-height: 18px;
  position: absolute;
}
.category-sidebar__submenu {
  padding: 0px;
  margin: 0;
  padding-left: 19px;
  max-height: 0px;
  overflow: hidden;
}
.category-sidebar__submenu--show {
  max-height: 9999px;
  margin-top: 10px;
}
.category-sidebar__more {
  color: #f3502e;
  text-decoration: underline;
  cursor: pointer;
}
.category-sidebar .select2-container .select2-selection--multiple {
  border: 0px;
  min-height: 40px;
  padding-right: 50px;
}
@media (max-width: 1024px) {
  .category-sidebar .select2-container .select2-selection--multiple {
    border: 1px solid #EFF1F3;
  }
}
.category-sidebar .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #f3502e;
  color: white;
  border: none;
  padding-right: 5px;
}
.category-sidebar .select2-search--inline {
  width: 100%;
  display: block;
}
.category-sidebar .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
}
.category-sidebar .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-right: 1px solid white;
  color: white;
}
.category-sidebar .select2-container--default .select2-search--inline .select2-search__field {
  width: initial !important;
  margin-top: 12px;
  margin-left: 10px;
}
.category-sidebar .select2-search__field::placeholder {
  opacity: 1;
  font-size: 14px;
  color: black;
}
.category-sidebar .select2-container--default.select2-container--focus .select2-selection--multiple, .category-sidebar .select2-dropdown {
  border: 0px;
}
@media (max-width: 1024px) {
  .category-sidebar .select2-container--default.select2-container--focus .select2-selection--multiple, .category-sidebar .select2-dropdown {
    border: 1px solid #EFF1F3;
  }
}
.category-sidebar .select2-container--default {
  position: relative;
}
.category-sidebar .select2-container--default:after {
  content: "\e908";
  font-family: "kmotor";
  right: 5px;
  top: 5px;
  position: absolute;
  width: 30px;
  height: 30px;
  color: #EFF1F3;
  font-size: 1.8rem;
  line-height: 30px;
  text-align: center;
}
.category-sidebar .select2-container--default.select2-container--focus::after {
  display: none;
}
.category-sidebar__buttons {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 24px;
}
.category-sidebar .filter-close {
  display: none;
}
@media (max-width: 576px) {
  .category-sidebar .filter-close {
    display: block;
  }
}

.page-nav {
  border-bottom: 1px solid #EFF1F3;
  margin-bottom: 5.5rem;
}
.page-nav__content {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-nav__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
}
.page-nav__item {
  font-weight: 500;
  font-size: 2.2rem;
  margin-right: 4%;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .page-nav__item {
    font-size: 1.6rem;
    padding-top: 15px;
  }
}
.page-nav__item a {
  color: #151617;
  text-decoration: none;
  padding-bottom: 30px;
  display: inline-block;
  position: relative;
}
@media (max-width: 768px) {
  .page-nav__item a {
    padding-bottom: 15px;
  }
}
.page-nav__item a:after {
  position: absolute;
  border-bottom: 2px solid white;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  left: 50%;
  width: 20px;
  bottom: 0px;
  content: "";
}
.page-nav__item a:hover {
  opacity: 0.8;
}
.page-nav__item--active a {
  color: #f3502e;
}
.page-nav__item--active a:after {
  border-bottom: 2px solid #f3502e;
}
.page-nav__logout {
  font-weight: 500;
  font-size: 2.2rem;
  padding-top: 30px;
}
@media (max-width: 768px) {
  .page-nav__logout {
    font-size: 1.6rem;
    padding-top: 15px;
  }
}
.page-nav__logout a {
  color: #151617;
  text-decoration: none;
  padding-bottom: 30px;
  display: inline-block;
  position: relative;
}
@media (max-width: 768px) {
  .page-nav__logout a {
    padding-bottom: 15px;
  }
}

.flashmessage {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px 20px;
  padding-left: 48px;
  box-sizing: border-box;
  color: white;
  -webkit-box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.46);
  box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.46);
  background-color: black;
  overflow: hidden;
}
.flashmessage:before {
  content: "x";
  position: absolute;
  color: white;
  font-weight: 400;
  font-family: "kmotor";
  padding: 10px;
  text-align: center;
  height: 100%;
  margin-right: 20px;
  width: 40px;
  box-sizing: border-box;
  left: 0px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.flashmessage a {
  color: white;
  text-decoration: underline;
}
.flashmessage--danger {
  border-right: 8px solid #e00000;
  color: #e00000;
}
.flashmessage--danger::before {
  content: "\e91b";
  background-color: #e00000;
}
.flashmessage--warning {
  border-right: 8px solid #F8CE29;
  color: #F8CE29;
}
.flashmessage--warning::before {
  content: "\e91b";
  background-color: #F8CE29;
}
.flashmessage--primary {
  border-right: 8px solid #f3502e;
  color: #f3502e;
}
.flashmessage--primary::before {
  content: "\e91d";
  background-color: #f3502e;
}
.flashmessage--info {
  border-right: 8px solid #0082D9;
  color: #0082D9;
}
.flashmessage--info::before {
  content: "\e91c";
  background-color: #0082D9;
}
.flashmessage--success {
  border-right: 8px solid #06B278;
  color: #06B278;
}
.flashmessage--success:before {
  content: "\e913";
  background-color: #06B278;
}
.flashmessage__text {
  text-align: center;
  flex: 1 1 auto;
}
.flashmessage__close {
  border: 0px;
  background: transparent;
  font-weight: 600;
  font-size: 1.8rem;
  color: inherit;
  padding-right: 0px;
  cursor: pointer;
  color: white;
}
.flashmessage__close svg {
  color: rgba(255, 255, 255, 0.5);
}
.flashmessage__close:hover svg {
  color: white;
}
.flashmessage__container {
  width: 1200px;
  max-width: 100%;
  position: fixed;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1053;
}

.articlesearch {
  position: relative;
  margin-bottom: 4.2rem;
  width: calc(50% - 15px);
  border: 1px solid #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .articlesearch {
    width: 100%;
  }
}
.articlesearch__input {
  color: #151617;
  font-size: 2rem;
  font-weight: 400;
  border: 0px;
  padding: 10px 15px;
  padding-left: 5px;
  width: 100%;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.articlesearch__input:focus {
  outline: none !important;
}
.articlesearch__button {
  position: absolute;
  top: 7px;
  right: 8px;
}

.popup {
  position: relative;
  background: #FFF;
  padding: 40px;
  width: auto;
  box-sizing: border-box;
  max-width: 450px;
  margin: 20px auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.popup__heading {
  font-size: 2.5rem;
  color: #f3502e;
  font-weight: 600;
  margin-top: 0px;
}
.popup .site-form__section-content {
  width: 100%;
}
.popup .site-form__section-content::before {
  display: none;
}

.mfp-close-btn-in .mfp-close {
  color: black;
  opacity: 1;
}
.mfp-close-btn-in .mfp-close:hover {
  color: #f3502e;
}

div.ajax-loading {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
}

div.ajax-loading::after {
  content: "";
  opacity: 0.5;
  filter: alpha(opacity=50);
  background: #FFFFFF;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.loader-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cssload-thecube {
  width: 73px;
  height: 73px;
  margin: auto;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  position: relative;
  transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.cssload-thecube .cssload-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3502e;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  -o-animation: cssload-fold-thecube 2.76s infinite linear both;
  -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
  -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
  -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
}

.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg);
  -o-transform: scale(1.1) rotateZ(90deg);
  -ms-transform: scale(1.1) rotateZ(90deg);
  -webkit-transform: scale(1.1) rotateZ(90deg);
  -moz-transform: scale(1.1) rotateZ(90deg);
}

.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg);
  -o-transform: scale(1.1) rotateZ(180deg);
  -ms-transform: scale(1.1) rotateZ(180deg);
  -webkit-transform: scale(1.1) rotateZ(180deg);
  -moz-transform: scale(1.1) rotateZ(180deg);
}

.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg);
  -o-transform: scale(1.1) rotateZ(270deg);
  -ms-transform: scale(1.1) rotateZ(270deg);
  -webkit-transform: scale(1.1) rotateZ(270deg);
  -moz-transform: scale(1.1) rotateZ(270deg);
}

.cssload-thecube .cssload-c2:before {
  animation-delay: 0.35s;
  -o-animation-delay: 0.35s;
  -ms-animation-delay: 0.35s;
  -webkit-animation-delay: 0.35s;
  -moz-animation-delay: 0.35s;
}

.cssload-thecube .cssload-c3:before {
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

.cssload-thecube .cssload-c4:before {
  animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -moz-animation-delay: 1.04s;
}

@keyframes cssload-fold-thecube {
  0%, 10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
@-o-keyframes cssload-fold-thecube {
  0%, 10% {
    -o-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -o-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -o-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
@-ms-keyframes cssload-fold-thecube {
  0%, 10% {
    -ms-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -ms-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -ms-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
@-webkit-keyframes cssload-fold-thecube {
  0%, 10% {
    -webkit-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
@-moz-keyframes cssload-fold-thecube {
  0%, 10% {
    -moz-transform: perspective(136px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -moz-transform: perspective(136px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -moz-transform: perspective(136px) rotateY(180deg);
    opacity: 0;
  }
}
.homepage-slider__head {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rem;
  border-bottom: 1px solid #EFF1F3;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.homepage-slider__head h2 {
  margin-top: 0px !important;
  color: #f3502e;
  position: relative;
  height: 100%;
}
.homepage-slider__head h2::after {
  content: "";
  border-bottom: 2px solid #f3502e;
  position: absolute;
  bottom: -1.8rem;
  display: block;
  width: 100%;
}
@media (max-width: 768px) {
  .homepage-slider__head h2::after {
    bottom: -1.6rem;
  }
}
.homepage-slider__head .btn {
  flex: 0 0 auto;
  line-height: 1;
  padding-right: 35px;
}
@media (max-width: 768px) {
  .homepage-slider__head .btn {
    font-size: 15px !important;
  }
}

.site-header__top {
  background-color: #151617;
  color: white;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 15px 15px;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10000;
  min-height: 61px;
  box-sizing: border-box;
  /*@include maxmobile{
      justify-content: flex-end;
  }*/
  /*&--nomobile{
      @include maxmobile{
          display: none;
      }
  }*/
}
@media (max-width: 1024px) {
  .site-header__top {
    padding: 8px 15px;
    min-height: 45px;
  }
}
.site-header__top-center {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .site-header__top-center {
    display: none;
  }
}
.site-header__top-center a {
  color: white;
  text-decoration: none;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  margin: 0px 5px;
}
.site-header__top-center a span {
  margin-right: 5px;
}
.site-header__top-callus {
  /*@include maxmobile{
      display: none;
  }*/
}
.site-header__top-callus .opening-hour-status-icon {
  margin-right: 5px;
}
.site-header__top-callus .opening-hour-status-text {
  text-transform: none;
}
@media (max-width: 450px) {
  .site-header__top-callus .time-container, .site-header__top-callus .opening-hours-sign {
    display: none;
  }
}
.site-header__top-contacts {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.site-header__top-right {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.site-header__top-openhours {
  padding-left: 15px;
}
@media (max-width: 1200px) {
  .site-header__top-openhours {
    display: none;
  }
}
.site-header__top-country {
  margin-left: 15px;
  padding: 5px 10px;
  color: white;
  text-transform: uppercase;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #f3502e;
  cursor: pointer;
}
.site-header__top-country a {
  color: white;
  text-decoration: none;
}
.site-header__top-country a:after {
  content: "\e915";
  font-family: "kmotor";
  margin-left: 5px;
}
@media (max-width: 768px) {
  .site-header__top-country {
    display: none;
  }
}
.site-header__top-language {
  padding-left: 15px;
}
@media (max-width: 768px) {
  .site-header__top-language {
    padding-left: 0px;
  }
}
.site-header__main {
  /*
  position: relative;*/
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  width: 100%;
  background: white;
  z-index: 1052;
  top: 61px;
  position: fixed;
  border-bottom: 1px solid #EFF1F3;
}
@media (max-width: 1024px) {
  .site-header__main {
    top: 45px;
  }
}
@media (max-width: 768px) {
  .site-header__main {
    min-height: 79px;
  }
}
.site-header__main--fixed {
  background: white;
  top: 0;
  position: fixed;
  border-bottom: 1px solid #EFF1F3;
}
@media (max-width: 768px) {
  .site-header__main--fixed .site-header__main-search {
    display: none;
    padding-top: 0px;
  }
}
.site-header__main-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 25px 15px !important;
}
@media (max-width: 1758px) {
  .site-header__main-container {
    margin-left: 115px !important;
  }
}
@media (max-width: 768px) {
  .site-header__main-container {
    flex-wrap: wrap;
    padding-top: 18px !important;
    margin-left: 66px !important;
    justify-content: space-between;
  }
}
@media (max-width: 450px) {
  .site-header__main-container {
    margin-left: 50px !important;
  }
}
.site-header__main-logo {
  width: 15%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: -5px 0px;
}
@media (max-width: 1758px) {
  .site-header__main-logo {
    width: auto;
    flex: 0 0 190px;
  }
}
@media (max-width: 768px) {
  .site-header__main-logo {
    flex: 0 0 auto;
  }
}
.site-header__main-logo .icon-logo {
  font-size: 5rem;
  margin-right: 1.7rem;
}
@media (max-width: 768px) {
  .site-header__main-logo .icon-logo {
    font-size: 3.5rem;
    margin-right: 1.3rem;
  }
}
@media (max-width: 450px) {
  .site-header__main-logo .icon-logo {
    margin-right: 1rem;
  }
}
.site-header__main-logo .logotyp {
  font-size: 3rem;
  line-height: 2.5rem;
  color: #151617;
}
@media (max-width: 450px) {
  .site-header__main-logo .logotyp {
    font-size: 2.5rem;
  }
}
.site-header__main-logo img {
  max-height: 65px;
}
@media (max-width: 768px) {
  .site-header__main-logo img {
    max-height: 55px;
  }
}
.site-header__main-search {
  width: 60%;
}
@media (max-width: 1758px) {
  .site-header__main-search {
    flex: 1 1 auto;
  }
}
@media (max-width: 768px) {
  .site-header__main-search {
    flex: 1 1 100%;
    order: 5;
    padding-top: 42px;
    margin-left: -66px;
  }
}
@media (max-width: 450px) {
  .site-header__main-search {
    margin-left: -50px;
  }
}
.site-header__main-right {
  flex: 0 0 25%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1758px) {
  .site-header__main-right {
    flex: 0 1 auto;
  }
}
.site-header__main-right > div > a {
  color: #151617;
  text-decoration: none;
  font-size: 2.6rem;
  margin: 0px 15px;
  display: inline-block;
  line-height: 1;
}
@media (max-width: 768px) {
  .site-header__main-right > div > a {
    margin: 0 5px;
  }
}
@media (max-width: 450px) {
  .site-header__main-right > div > a {
    font-size: 2rem;
    padding: 5px;
  }
}
.site-header__main-right .site-header__user > a {
  margin-right: 0px;
}
.site-header__main-right .site-header__user-name {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid black;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}
.site-header__main-right .site-header__user-name:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 10px;
  right: -10px;
  border: 1px solid white;
  background-color: #11C412;
  display: block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -5px;
  right: -6px;
}
.site-header__cart {
  position: relative;
}
.site-header__cart > a:hover + .itemscart {
  display: block;
}
.site-header__cart-pcs {
  position: absolute;
  background: #f3502e;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -4px;
  right: -2px;
}
.site-header__cart-summary {
  display: none;
}
.site-header__user {
  position: relative;
  cursor: pointer;
}
.site-header__user-popup {
  position: absolute;
  width: 250px;
  box-sizing: border-box;
  background: white;
  top: 100%;
  right: 0px;
  z-index: 250000;
  font-size: 1.8rem;
  -webkit-box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  -moz-box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  margin-top: 15px;
  cursor: default;
}
.site-header__user-popup--active {
  visibility: visible;
  opacity: 1;
}
.site-header__user-reminder {
  position: absolute;
  width: 280px;
  box-sizing: border-box;
  background: white;
  top: 100%;
  right: 0px;
  z-index: 2500;
  font-size: 1.8rem;
  -webkit-box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  -moz-box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-top: 15px;
  padding: 15px;
  box-sizing: border-box;
  background: #151617;
  color: white;
  -webkit-animation: slide-bl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bl 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  opacity: 0;
  visibility: hidden;
}
.site-header__user-reminder p {
  margin: 0px;
}
.site-header__user-reminder--active {
  visibility: visible;
  opacity: 1;
}
.site-header__user-reminderclose {
  position: absolute;
  color: white;
  right: 10px;
  top: 10px;
}
.site-header__user-heading {
  background: #EFF1F3;
  padding: 20px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-top-right: 5px;
  -moz-border-radius-bottom-right: 0px;
  -moz-border-radius-bottom-left: 0px;
  -moz-border-radius-top-left: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  font-size: 1.8rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
.site-header__user-heading .icon {
  color: #808080;
  margin-right: 15px;
}
.site-header__user form {
  padding: 20px;
}

.site-footer {
  background: #151617;
}
.site-footer__address {
  padding: 3rem 0rem;
}
.site-footer__address-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 576px) {
  .site-footer__address-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.site-footer__address-logo {
  margin-right: 40px;
}
@media (max-width: 576px) {
  .site-footer__address-logo {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
.site-footer__address-logo img {
  max-height: 100px;
}
.site-footer__address-text {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
}
.site-footer__address-text a {
  color: white;
  padding-left: 20px;
  position: relative;
  margin-bottom: 5px;
  display: inline-block;
  text-decoration: none;
}
.site-footer__address-text a .icon {
  position: absolute;
  left: 0px;
}
.site-footer__address-text a .icon::before {
  color: white;
}
.site-footer__address-text a:hover {
  text-decoration: underline;
}
.site-footer__top-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 4.9rem 15px !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 1024px) {
  .site-footer__top-container {
    padding: 2rem 15px !important;
  }
}
.site-footer__top-link {
  font-size: 2.2rem;
  color: white;
  text-decoration: none;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
.site-footer__top-link .icon {
  margin-right: 1.5rem;
  width: 22px;
}
.site-footer__top-link .icon:before {
  color: #f3502e;
}
@media (max-width: 1024px) {
  .site-footer__top .site-footer__column {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.site-footer__main-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 4.9rem 15px !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .site-footer__main-container {
    padding: 2rem 15px !important;
  }
}
.site-footer__main-heading {
  margin-top: 0px;
  margin-bottom: 1.8rem;
  font-size: 2rem;
  color: white;
  font-weight: 300;
}
.site-footer__main-list {
  padding: 0;
  margin: 0;
}
.site-footer__main-list li {
  list-style: none;
  margin-bottom: 1.2rem;
}
.site-footer__main-list li a {
  text-decoration: none;
  color: #808080;
  font-size: 1.8rem;
}
.site-footer__main-list li a:hover {
  color: white;
}
.site-footer__copy-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 2.9rem 15px !important;
  font-size: 2rem;
  color: white;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 450px) {
  .site-footer__copy-container {
    flex-direction: column;
  }
}
.site-footer__copy img {
  max-height: 3.3rem;
  filter: grayscale(90%);
}
.site-footer__copy img:hover {
  filter: none;
}
.site-footer__copy-left {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
@media (max-width: 450px) {
  .site-footer__copy-left {
    margin-bottom: 1.5rem;
  }
}
.site-footer__copy-left a {
  text-decoration: none;
}
.site-footer__copy-left .icon {
  margin-right: 1.5rem;
}
.site-footer__column {
  width: 25%;
}
@media (max-width: 576px) {
  .site-footer__column {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
.site-footer__column--double {
  width: 50%;
}
@media (max-width: 576px) {
  .site-footer__column--double {
    width: 100%;
  }
}
.site-footer__logo {
  font-size: 3.3rem;
  color: white;
  text-decoration: none;
}
.site-footer__logo .icon-logo {
  font-size: 3.6rem;
  margin-right: 1.5rem;
}
.site-footer__logo img {
  display: block;
}

.site-nav__title {
  background: #f3502e;
  color: white;
  font-size: 2.2rem;
  display: block;
  height: 100%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  padding: 0px 1.8rem;
  cursor: pointer;
  position: absolute;
  border-bottom: 1px solid #f3502e;
  margin-bottom: -1px;
}
@media (max-width: 768px) {
  .site-nav__title {
    font-size: 0px;
    justify-content: center;
    height: 79px;
    width: 66px;
    padding: 0px !important;
  }
}
@media (max-width: 450px) {
  .site-nav__title {
    width: 50px;
  }
}
.site-nav__title .icon-menu {
  margin-right: 1.8rem;
}
@media (max-width: 768px) {
  .site-nav__title .icon-menu {
    font-size: 4rem !important;
    margin-right: 0;
  }
}
.site-nav__main {
  position: fixed;
  background: white;
  left: 0px;
  width: 479px;
  max-width: 100%;
  padding: 156px 92px 74px 74px;
  box-sizing: border-box;
  top: 0px;
  z-index: 3;
  min-height: 100vh;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  border-right: 1px solid #EFF1F3;
  transform: translate(-479px, 0px);
  -webkit-transform: translate(-479px, 0px);
  -moz-transform: translate(-479px, 0px);
  -ms-transform: translate(-479px, 0px);
  -moz-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  -webkit-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
}
@media (max-width: 1024px) {
  .site-nav__main {
    position: absolute;
    padding: 50px 20px 20px 40px;
  }
}
.site-nav__main--active {
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
}
.site-nav__main-close {
  position: absolute;
  top: 105px;
  left: 32px;
  cursor: pointer;
  font-size: 2.5rem;
}
.site-nav__main-close:hover {
  color: #f3502e;
}
@media (max-width: 1024px) {
  .site-nav__main-close {
    top: 10px;
    left: 15px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
}
@media (max-width: 1024px) {
  .site-nav__main-close .icon-chevron-left {
    position: absolute;
    left: auto;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='close'%3E%3Crect width='24' height='24' transform='rotate(180 12 12)' opacity='0'/%3E%3Cpath d='M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
  }
}
@media (max-width: 1024px) {
  .site-nav__main-close .icon-chevron-left:before {
    content: "";
  }
}
.site-nav__main-bottom {
  margin-top: auto;
}
@media (max-width: 1024px) {
  .site-nav__main-bottom {
    z-index: 2;
  }
}
.site-nav__main-logo {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  color: #151617;
}
.site-nav__main-logo .icon::before {
  color: #151617;
}
.site-nav__main-logo .icon {
  font-size: 4.5rem;
}
.site-nav__main-logo .logotyp {
  font-size: 3rem;
  margin-left: 1.3rem;
}
.site-nav__main-logo img {
  max-height: 65px;
}
.site-nav__main-country {
  padding: 5px 10px;
  color: white;
  text-transform: uppercase;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #f3502e;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: none;
}
@media (max-width: 768px) {
  .site-nav__main-country {
    display: inline-block;
  }
}
.site-nav__main-country a {
  color: white;
  text-decoration: none;
}
.site-nav__main-country a:after {
  content: "\e915";
  font-family: "kmotor";
  margin-left: 5px;
}
.site-nav__main-address {
  text-transform: uppercase;
  font-size: 2.2rem;
}
.site-nav__main-contacts {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
}
.site-nav__main-contacts a {
  color: #151617;
  text-decoration: none;
}
.site-nav__main-contacts .icon {
  margin-right: 5px;
}
.site-nav__main-contacts .icon:before {
  color: black;
}
.site-nav__list {
  margin-bottom: 3rem;
}
.site-nav__list-container {
  margin: 0px;
  padding: 0px;
}
.site-nav__list-item {
  list-style: none;
  margin-bottom: 15px;
  font-size: 3rem;
}
.site-nav__list-item--smaller {
  font-size: 1.8rem;
}
.site-nav__list-link {
  color: #151617;
  text-decoration: none;
}

.product-detail__top-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px;
}
@media (max-width: 768px) {
  .product-detail__top-container {
    margin: 0px;
  }
}
.product-detail__top-heading {
  margin: 40px 0px 15px 0px;
}
.product-detail__top-heading h1 {
  margin: 15px 0px;
}
.product-detail__top-heading strong {
  color: #909090;
  font-weight: 400;
}
.product-detail__imageinfo {
  width: 75%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
}
@media (max-width: 1200px) {
  .product-detail__imageinfo {
    width: 65%;
  }
}
@media (max-width: 1024px) {
  .product-detail__imageinfo {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .product-detail__imageinfo {
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
  }
}
.product-detail .brand-logo {
  max-width: 180px;
  max-height: 50px;
}
.product-detail__image {
  width: 55%;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
}
@media (max-width: 768px) {
  .product-detail__image {
    width: 100%;
    padding: 0px;
  }
}
.product-detail__info {
  width: 45%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .product-detail__info {
    width: 100%;
    padding: 0px;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
.product-detail__info-logo {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
.product-detail__info h1 {
  margin-top: 15px;
}
.product-detail__info-switcher {
  color: #f3502e;
  border-bottom: 1px solid #f3502e;
  text-align: center;
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;
  display: block;
}
.product-detail__info-switcher span {
  display: inline-block;
  background-color: white;
  padding: 5px 10px;
  position: absolute;
  left: 50%;
  top: -16px;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  cursor: pointer;
}
.product-detail__info-switcher span:after {
  content: "\e915";
  font-family: "kmotor";
  margin-left: 8px;
  vertical-align: -2px;
}
.product-detail__price {
  width: 25%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .product-detail__price {
    width: 35%;
  }
}
@media (max-width: 1024px) {
  .product-detail__price {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .product-detail__price {
    width: 100%;
    padding: 0px;
  }
}
.product-detail__price-content {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #EFF1F3;
}
@media (max-width: 1024px) {
  .product-detail__price-content {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.product-detail__price-item {
  border-bottom: 1px solid white;
  box-sizing: border-box;
  padding: 26px 34px;
}
@media (max-width: 1024px) {
  .product-detail__price-item {
    flex: 0 0 33.333%;
    border-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .product-detail__price-item {
    flex: 1 1 100%;
    border-bottom: 1px solid white;
  }
}
@media (max-width: 576px) {
  .product-detail__price-item {
    padding: 20px 30px;
  }
}
@media (max-width: 1024px) {
  .product-detail__price-item--last {
    flex: 1 1 100%;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    align-items: center;
    border-top: 1px solid white;
    row-gap: 40px;
  }
}
@media (max-width: 768px) {
  .product-detail__price-item--last {
    flex-wrap: wrap;
  }
}
.product-detail__price-badges {
  margin: -5px -8px;
  padding: 26px 34px;
  padding-bottom: 0px;
}
@media (max-width: 1024px) {
  .product-detail__price-badges {
    flex: 1 1 100%;
  }
}
@media (max-width: 576px) {
  .product-detail__price-badges {
    padding: 20px 30px;
  }
}
.product-detail__price-heading {
  color: #b2b2b2;
  display: block;
}
.product-detail__price-retail {
  display: block;
  font-size: 2.2rem;
  margin-top: 5px;
  margin-bottom: 2px;
}
.product-detail__price-stock {
  padding: 0px 34px;
  padding-top: 26px;
  box-sizing: border-box;
}
.product-detail__price-stock .product-detail__buy-stock {
  margin-bottom: 0px;
}
@media (max-width: 1024px) {
  .product-detail__price-stock {
    flex: 0 0 33.333%;
  }
}
@media (max-width: 768px) {
  .product-detail__price-stock {
    flex: 1 1 100%;
    padding-bottom: 26px;
  }
}
.product-detail__price-stock .stock-tooltip {
  position: absolute;
  margin-left: 8px;
}
.product-detail__price-main {
  color: #f3502e;
  font-size: 3rem;
  display: block;
  margin-top: 5px;
  margin-bottom: 2px;
}
.product-detail__price-vat {
  font-size: 1.6rem;
}
.product-detail__price .price-shipping-fees {
  padding-left: 25px;
  position: relative;
  margin-top: 10px;
  display: inline-block;
}
.product-detail__price .price-shipping-fees:before {
  content: "\e92c";
  font-family: "kmotor";
  left: 0px;
  position: absolute;
}
@media (max-width: 1024px) {
  .product-detail__buy {
    flex: 1 0 275px;
  }
}
@media (max-width: 768px) {
  .product-detail__buy {
    flex: 1 1 auto;
  }
}
.product-detail__ean {
  text-align: center;
  margin-top: 15px;
}
@media (max-width: 1024px) {
  .product-detail__ean {
    flex: 1 0 50%;
    text-align: center;
    margin-top: 0px;
  }
}
.product-detail__ean svg {
  max-width: 100%;
}
.product-detail__ean p {
  margin: 0px 0px 10px 0px;
}
.product-detail__ean p strong {
  color: #909090;
  font-weight: 400;
}
.product-detail__data {
  width: 100%;
}
.product-detail__data tr > td:first-child {
  color: #b2b2b2;
  width: 45%;
  padding-right: 5px;
  box-sizing: border-box;
}
.product-detail__data tr > td {
  border-bottom: 1px solid #EFF1F3;
  padding: 5px 0px;
}
.product-detail__data tr > td:last-child {
  overflow-wrap: break-word;
  word-break: break-word;
}
.product-detail__data tr:last-child > td {
  border-bottom: 0px;
}
.product-detail__data--full tr > td:first-child {
  width: auto;
}
.product-detail__data--full tr > td:last-child {
  text-align: right;
}
.product-detail__data-showall {
  color: #f3502e;
  margin-top: 10px;
  margin-bottom: 4rem;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  position: relative;
}
.product-detail__data-showall::after {
  content: "";
  border-bottom: 1px solid #f3502e;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}
.product-detail__data-showall span {
  display: inline-block;
  position: relative;
  background-color: #fff;
  padding: 5px 10px;
  z-index: 10;
}
.product-detail__data-showall span::after {
  content: "\e915";
  font-family: "kmotor";
  margin-left: 8px;
  vertical-align: -2px;
}
.product-detail__data-showall span:nth-child(2) {
  display: none;
}
.product-detail__data-showall--open span {
  display: none;
}
.product-detail__data-showall--open span::after {
  content: "\e914";
}
.product-detail__data-showall--open span:nth-child(2) {
  display: inline-block;
}
.product-detail__data tr.class-collapse {
  display: none;
}
.product-detail__data--open tr.class-collapse {
  display: table-row;
}
.product-detail__buy {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.product-detail__buy .product-item__buy {
  width: 100%;
}
.product-detail__buy-stock {
  margin-bottom: 2rem;
  width: 100%;
}
.product-detail__buy-stock tr > td:last-child {
  text-align: right;
}
.product-detail__buy-form {
  background: white;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-top-right: 0px;
  -moz-border-radius-bottom-right: 0px;
  -moz-border-radius-bottom-left: 5px;
  -moz-border-radius-top-left: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.product-detail__buy-form .icon {
  color: white;
  font-weight: 400;
}
.product-detail__buy-button {
  flex: 1 1 auto;
  padding: 10px 12px !important;
}
.product-detail__buy-input {
  flex: 0 0 100px;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 8px 13px;
  box-sizing: border-box;
}
.product-detail__buy-input input {
  width: 100%;
  font-size: 20px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: center;
  height: 100%;
}
.product-detail__buy-input input:focus {
  border: 0;
  outline: 0;
}
.product-detail__buy-input input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.product-detail__buy-input input[type=number]::-webkit-inner-spin-button,
.product-detail__buy-input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.product-detail__buy-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.product-detail__buy-input button:before, .product-detail__buy-input button:after {
  display: inline-block;
  position: absolute;
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #909090;
  transform: translate(-50%, -50%);
}
.product-detail__buy-input button:hover::before, .product-detail__buy-input button:hover::after {
  background-color: black;
}
.product-detail__buy-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.product-detail__content {
  overflow: hidden;
}
.product-detail__content-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -20px;
}
@media (max-width: 1024px) {
  .product-detail__content-container {
    flex-wrap: wrap;
    margin: 0px;
  }
}
.product-detail__content-left, .product-detail__content-right {
  width: 50%;
  box-sizing: border-box;
  padding: 20px;
}
@media (max-width: 1024px) {
  .product-detail__content-left, .product-detail__content-right {
    width: 100%;
    padding: 0px;
  }
}
.product-detail__content-text {
  font-size: 1.8rem;
  padding-bottom: 20px;
}
.product-detail__box {
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .product-detail__box {
    margin-bottom: 20px;
  }
}
.product-detail__box-header {
  border-bottom: 1px solid white;
  background: #EFF1F3;
  padding: 16px 24px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-top-right: 5px;
  -moz-border-radius-bottom-right: 0px;
  -moz-border-radius-bottom-left: 0px;
  -moz-border-radius-top-left: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.product-detail__box-header h2 {
  margin: 0px;
  font-size: 2.2rem;
}
.product-detail__box-body {
  background: #EFF1F3;
  padding: 16px 24px;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-top-left-radius: 0px;
  -moz-border-radius-top-right: 0px;
  -moz-border-radius-bottom-right: 5px;
  -moz-border-radius-bottom-left: 5px;
  -moz-border-radius-top-left: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.product-detail__box-body--nopadding {
  padding: 15px 0px;
}
.product-detail__box-body--list {
  padding: 0px;
}
.product-detail__box-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.product-detail__box-list--columns {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
.product-detail__box-item {
  padding: 8px 24px;
  border-bottom: 1px solid white;
}
.product-detail__box-item--columns {
  width: 33.333%;
  box-sizing: border-box;
  border-right: 1px solid white;
  text-align: left;
}
@media (max-width: 768px) {
  .product-detail__box-item--columns {
    width: 50%;
    border-right: 0px;
  }
}
@media (max-width: 576px) {
  .product-detail__box-item--columns {
    width: 100%;
    border-right: 0px;
    text-align: left;
  }
}
.product-detail__box-item--columns:nth-child(3n+3) {
  border-right: 0px;
}
@media (max-width: 768px) {
  .product-detail__box-item--columns:nth-child(2n-1) {
    border-right: 1px solid white;
  }
}
@media (max-width: 576px) {
  .product-detail__box-item--columns:nth-child(2n-1) {
    border-right: 0px;
  }
}
.product-detail__box-item:last-child {
  border: 0px;
}
.product-detail__box-item--hidden {
  display: none;
}
.product-detail__box-item--paddingtop {
  padding-top: 16px;
}
.product-detail__box-item .badge-table {
  display: inline-block;
  color: white;
  text-decoration: none;
  margin-right: 5px;
  padding: 2px 5px;
}
.product-detail__box-more {
  text-align: center;
  padding-top: 15px;
  display: block;
  cursor: pointer;
  padding-bottom: 15px;
}
.product-detail__box-more .less {
  display: none;
}
.product-detail__pack {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.product-detail__pack-item {
  position: relative;
}
.product-detail__pack-item ul {
  list-style: none;
  margin-top: 20px;
  margin-left: 4%;
  padding-left: 0px;
}
.product-detail__pack-item ul li {
  position: relative;
  padding-left: 20px;
}
.product-detail__pack-item:first-child .product-detail__pack-text--main {
  border-top: 0px;
}
.product-detail__pack-text {
  position: relative;
}
.product-detail__pack-text:before {
  content: "✓";
  font-size: 18px;
  color: #f3502e;
  position: absolute;
  left: 0px;
}
.product-detail__pack-text .head {
  text-decoration: none;
  font-weight: 400;
  color: #f3502e;
}
.product-detail__pack-text--main {
  font-size: 18px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 16px 24px;
  padding-left: 50px;
}
.product-detail__pack-text--main:before {
  font-size: 26px;
  left: 24px;
  top: 4px;
}
.product-detail__pack-text--main .head {
  font-weight: 700 !important;
}
.product-detail__pack-text--sub {
  margin-bottom: 16px;
  position: relative;
  padding-left: 20px;
  top: 2px;
}
.product-detail__pack-numbers {
  display: block;
}
.product-detail__pack-numbers .number {
  display: none;
  padding: 0px 2px;
}
.product-detail__pack-numbers .number:nth-child(1), .product-detail__pack-numbers .number:nth-child(2), .product-detail__pack-numbers .number:nth-child(3) {
  display: inline-block;
}
.product-detail__pack-numbers .more {
  color: #f3502e;
  cursor: pointer;
}
.product-detail #contents, .product-detail #ispart, .product-detail #alternatives, .product-detail #description {
  padding-top: 110px;
  margin-top: -110px;
}

.product-detail__buy-stock th {
  text-align: left;
  width: 16px;
}

.category {
  padding-top: 4rem;
}
.category__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: flex-start;
}
.category__switch {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0px;
}
.category__switch a {
  color: #D8D8D8;
  padding: 5px;
  padding-bottom: 2px;
  margin: 5px;
}
.category__switch a.active {
  color: #151617;
  background: #EFF1F3;
}
.category__switch a:hover {
  color: #151617;
}
@media (max-width: 576px) {
  .category__switch a.d-none {
    display: none;
  }
}
.category__nav {
  text-align: center;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .category__nav {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.category__feed {
  position: relative;
}
.category__controls {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.category__controls-filters {
  opacity: 0;
  padding: 10px;
}
@media (max-width: 1024px) {
  .category__controls-filters {
    opacity: 1;
    margin-right: 20px;
  }
}
@media (max-width: 576px) {
  .category__controls-filters {
    flex: 1 1 auto;
  }
}
.category__controls-filters i {
  margin-right: 10px;
  vertical-align: -2px;
}
.category .category-content {
  flex: 1 1 auto;
  padding-left: 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 20;
}
@media (max-width: 1024px) {
  .category .category-content {
    padding-left: 0;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .category .category-content > h1 {
    order: -1;
    margin-top: 0px;
  }
}
@media (max-width: 1024px) {
  .category .category-content > p {
    order: -1;
    margin-top: 0px;
  }
}
.category .category-content--reload {
  position: relative;
  overflow: hidden;
}
.category .category-content--reload:after, .category .category-content--reload::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0px;
  background-color: white;
  opacity: 0.8;
  z-index: 20;
  top: 0px;
}
.category .category-content--reload:before {
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  z-index: 21;
}
.category .category-search {
  background: #f3502e;
  padding: 20px;
  width: 100%;
}

.cart-nav {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  padding: 4.5rem 0;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .cart-nav {
    align-items: flex-start;
    padding: 2rem 0rem;
  }
}
.cart-nav__item {
  flex: 0 0 auto;
  position: relative;
  list-style: none;
}
@media (max-width: 768px) {
  .cart-nav__item {
    margin: 10px 0px;
  }
}
@media (max-width: 576px) {
  .cart-nav__item {
    flex: 0 0 20%;
    text-align: center;
  }
}
.cart-nav__item-container {
  align-items: center;
  text-decoration: none;
}
.cart-nav__item::after {
  content: "\e911";
  font-family: "kmotor";
  font-size: 3rem;
  color: #D8D8D8;
  position: absolute;
  right: -40%;
  top: 2.5rem;
}
@media (max-width: 1200px) {
  .cart-nav__item::after {
    right: -3rem;
  }
}
@media (max-width: 1024px) {
  .cart-nav__item::after {
    display: none;
  }
}
@media (min-width: 1200px) {
  .cart-nav__item:nth-child(3)::after {
    right: -25%;
  }
}
.cart-nav__item:last-child {
  padding-right: 0;
}
.cart-nav__item:last-child::after {
  display: none;
}
.cart-nav__item--complete .cart-nav__item-number {
  border: 2px solid #f3502e;
  color: white;
  background: #f3502e;
}
.cart-nav__item--complete:after {
  color: #f3502e;
}
.cart-nav__item--active .cart-nav__item-number {
  border: 2px solid #f3502e;
  color: #f3502e;
}
.cart-nav__item-number {
  width: 8rem;
  display: inline-block;
  height: 8rem;
  text-align: center;
  line-height: 8.5rem;
  font-size: 4rem;
  border: 2px solid #D8D8D8;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #151617;
  vertical-align: middle;
}
@media (max-width: 1024px) {
  .cart-nav__item-number {
    height: 3rem;
    width: 3rem;
    font-size: 1.5rem;
    line-height: 3rem;
  }
}
.cart-nav__item-number i {
  font-size: 3rem;
}
@media (max-width: 1024px) {
  .cart-nav__item-number i {
    font-size: 1.2rem;
  }
}
.cart-nav__item-text {
  display: inline-block;
  font-size: 3rem;
  padding: 0 2rem;
  color: #151617;
  vertical-align: middle;
}
@media (max-width: 1024px) {
  .cart-nav__item-text {
    padding: 0 1rem;
  }
}
@media (max-width: 768px) {
  .cart-nav__item-text {
    font-size: 1.8rem;
  }
}
@media (max-width: 576px) {
  .cart-nav__item-text {
    display: block;
    margin-top: 10px;
    text-align: center;
    padding: 0;
  }
}

.cart-table {
  border-top: 1px solid #EFF1F3;
}
.cart-table__row {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid #EFF1F3;
}
@media (max-width: 1024px) {
  .cart-table__row {
    flex-wrap: wrap;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2.5rem;
  }
}
.cart-table__productmain {
  flex: 1 0 48%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .cart-table__productmain {
    flex: 0 0 85%;
    flex-wrap: wrap;
    padding-left: 15px;
    justify-content: flex-start;
  }
}
.cart-table__pic {
  flex: 0 0 8%;
}
@media (max-width: 1024px) {
  .cart-table__pic {
    flex: 0 0 15%;
  }
}
.cart-table__pic img {
  max-width: 100%;
}
.cart-table__name {
  font-size: 2rem;
  flex: 0 0 58%;
  padding: 0rem 1.5rem;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .cart-table__name {
    flex: 0 0 100%;
    padding: 0px;
    margin-bottom: 10px;
  }
}
.cart-table__unitprice {
  color: #f3502e;
  flex: 0 0 15%;
  padding: 0 1.5rem;
  box-sizing: border-box;
  text-align: right;
}
@media (max-width: 1024px) {
  .cart-table__unitprice {
    flex: 1 1 100%;
    padding: 0px;
    margin-top: 15px;
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .cart-table__unitprice br {
    display: none;
  }
}
.cart-table__stock {
  flex: 0 0 20%;
}
@media (max-width: 1024px) {
  .cart-table__stock {
    flex: 0 0 auto;
  }
}
.cart-table__pcs {
  flex: 0 0 185px;
  padding: 0 20px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .cart-table__pcs {
    margin-top: 20px;
    padding: 0px;
    padding-left: 15px;
    flex: 0 0 auto;
    margin-left: 15%;
  }
}
.cart-table__pcs input {
  max-width: 100%;
  border: 1px solid #EFF1F3;
  height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  font-family: "Barlow Condensed";
  font-size: 2rem;
  box-sizing: border-box;
}
.cart-table__pcs input:focus {
  border: 0px;
}
.cart-table__pcs .quantity {
  height: 44px;
  position: relative;
  width: 86px;
}
.cart-table__pcs .quantity .quantity-nav {
  right: 1px;
  position: absolute;
  top: 1px;
  background: white;
  height: 38px;
  width: 22px;
  border-left: 1px solid #EFF1F3;
  overflow: hidden;
}
.cart-table__pcs .quantity .quantity-nav .quantity-button {
  text-align: center;
  cursor: pointer;
}
.cart-table__pcs .quantity .quantity-nav .quantity-up {
  border-bottom: 1px solid #EFF1F3;
  height: 19px;
}
.cart-table__pcs .input-group {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
.cart-table__pcs .btn {
  background: transparent;
  padding: 0px;
  font-size: 1.6rem;
  border: 0px;
  margin-left: 5px;
}
.cart-table__price {
  flex: 0 1 15%;
  padding: 0px 2rem;
  box-sizing: border-box;
  text-align: right;
}
@media (max-width: 1024px) {
  .cart-table__price {
    flex: 0 0 100%;
    align-self: flex-end;
    text-align: right;
    padding: 0px;
  }
}
.cart-table__price-main {
  color: #f3502e;
  font-size: 2.2rem;
  display: block;
  text-align: right;
}
@media (max-width: 1024px) {
  .cart-table__price-main {
    margin-top: 40px;
  }
}
.cart-table__price-main .price-vat {
  display: block;
}
.cart-table__price-vat {
  font-size: 1.4rem;
  display: block;
}
.cart-table__remove {
  flex: 0 0 20px;
}
@media (max-width: 1024px) {
  .cart-table__remove {
    position: absolute;
    top: 8px;
    right: 0px;
  }
}
.cart-table__remove a {
  color: #151617;
  text-decoration: none;
}

.cart-undertable {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 3rem;
}
@media (max-width: 1024px) {
  .cart-undertable {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 1rem;
  }
}
.cart-undertable__buttons {
  flex: 1 1 100%;
  padding-right: 25px;
  box-sizing: border-box;
}
.cart-undertable__buttons a {
  color: #151617;
  text-decoration: none;
  margin: 0px 2%;
}
.cart-undertable__buttons a:first-of-type {
  margin-left: 0px;
}
.cart-undertable__buttons a:last-of-type {
  margin-right: 0px;
}
.cart-undertable__buttons a .icon, .cart-undertable__buttons a i, .cart-undertable__buttons a svg {
  color: #f3502e;
  margin-right: 8px;
}
.cart-undertable__buttons a span {
  color: #151617;
  text-decoration: underline;
}
.cart-undertable__totals {
  flex: 0 0 350px;
  max-width: 100%;
}
@media (max-width: 1024px) {
  .cart-undertable__totals {
    margin-top: 5rem;
  }
}

.cart-totals__line {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;
}
.cart-totals__line--first {
  font-size: 1.4rem;
}
.cart-totals__line--second {
  font-size: 1.8rem;
}
.cart-totals__line--third {
  font-size: 2.2rem;
  color: #f3502e;
}

.cart-buttons {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6%;
}
@media (max-width: 576px) {
  .cart-buttons {
    flex-direction: column-reverse;
    align-items: stretch;
  }
}
@media (max-width: 1024px) {
  .cart-buttons--payment {
    align-items: flex-end;
  }
}
@media (max-width: 576px) {
  .cart-buttons--payment {
    align-items: stretch;
  }
}
.cart-buttons--payment .text-danger {
  margin-top: 8px;
  display: inline-block;
  font-size: 1.4rem;
}
.cart-buttons--bottom {
  align-items: flex-end;
}
@media (max-width: 576px) {
  .cart-buttons--bottom {
    align-items: stretch;
  }
}
.cart-buttons--right {
  justify-content: flex-end;
  margin: 0 -5px;
  margin-top: 6%;
}
.cart-buttons .btn {
  margin: 5px;
}
@media (max-width: 576px) {
  .cart-buttons .btn {
    flex: 1 1 auto;
    margin: 5px 0px;
  }
}
.cart-buttons #frm-customerDisposableControl-customerDisposableForm-send_message {
  display: none;
}
.cart-buttons__two {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
}
.cart-buttons__two .btn {
  margin: 5px;
}
.cart-buttons__right {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .cart-buttons__right {
    flex-direction: column;
    align-items: flex-end;
  }
}
@media (max-width: 576px) {
  .cart-buttons__right {
    align-items: stretch;
  }
}
.cart-buttons__right .btn {
  margin-left: 5%;
  flex: 0 0 auto;
}
@media (max-width: 1024px) {
  .cart-buttons__right .btn {
    margin-left: 0;
    margin-top: 2rem;
  }
}
@media (max-width: 576px) {
  .cart-buttons__right .btn {
    margin: 10px 0px 10px 0px;
  }
}
.cart-buttons__right label {
  width: 250px;
}
@media (max-width: 576px) {
  .cart-buttons__right label {
    width: 100%;
  }
}
.cart-buttons__right--long {
  flex-direction: column;
  flex: 0 0 33.333%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .cart-buttons__right--long {
    flex: 0 0 50%;
  }
}
@media (max-width: 576px) {
  .cart-buttons__right--long {
    align-items: stretch;
    padding: 0px;
  }
}
.cart-buttons__right--long label {
  width: 100%;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
}
.cart-buttons__right--long span {
  flex: 1 1 100%;
}
.cart-buttons__right--long .btn {
  align-self: flex-end;
  margin-top: 2rem;
  margin-right: -15px;
}
@media (max-width: 576px) {
  .cart-buttons__right--long .btn {
    margin-right: 0px;
    align-self: auto;
  }
}

.cart-customer {
  overflow: hidden;
  margin-bottom: 3rem;
}
.cart-customer__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
}
@media (max-width: 1024px) {
  .cart-customer__container {
    flex-wrap: wrap;
  }
}
.cart-customer__column {
  width: 33.333%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .cart-customer__column {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .cart-customer__column {
    width: 100%;
  }
}
.cart-customer__column--sticky {
  position: sticky;
  top: 20px;
}
@media (max-width: 1024px) {
  .cart-customer__column--sticky {
    width: 100%;
  }
}
.cart-customer__column h2:first-child {
  margin-top: 0px;
}
.cart-customer__delivery-tool {
  margin-top: -3.8rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .cart-customer__delivery-tool {
    margin-top: -0.8rem;
  }
}
.cart-customer__delivery-tool span {
  color: #f3502e;
  cursor: pointer;
  margin: 5px;
}
.cart-customer__delivery-tool span .icon {
  margin-right: 5px;
}
.cart-customer__delivery-tool span .icon:before {
  color: #f3502e;
}
.cart-customer__delivery-add {
  max-height: 0px;
  overflow: hidden;
}
.cart-customer__delivery-add--visible {
  max-height: 9999px;
  padding: 18px 24px;
}
.cart-customer__delivery-add h3 {
  margin: 0px !important;
}
.cart-customer__delivery-add .site-form__section-content::before {
  display: none;
}
.cart-customer__delivery-change {
  max-height: 0px;
  overflow: hidden;
}
.cart-customer__delivery-change h3 {
  margin-bottom: 2rem;
}
.cart-customer__delivery-change--visible {
  max-height: 9999px;
  padding: 18px 24px;
}

.cart-summary {
  border: 1px solid #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-sizing: border-box;
}
.cart-summary__header {
  padding: 18px 24px;
  border-bottom: 1px solid #EFF1F3;
}
.cart-summary__header h2 {
  font-size: 2.2rem;
  margin: 0;
}
.cart-summary__body {
  padding: 18px 24px;
  max-height: 30vh;
  overflow-y: auto;
}
.cart-summary__item {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.cart-summary__item:last-child {
  margin-bottom: 0px;
}
.cart-summary__item-img {
  flex: 0 0 40px;
}
.cart-summary__item-text {
  flex: 1 1 auto;
  padding: 0px 10px;
  box-sizing: border-box;
}
.cart-summary__item-price {
  text-align: right;
  flex: 0 0 80px;
}
.cart-summary__total {
  padding: 18px 24px;
  border-top: 1px solid #EFF1F3;
}
.cart-summary__shippay {
  padding: 18px 24px;
  border-top: 1px solid #EFF1F3;
}
.cart-summary__shippay-line {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.9rem;
}
.cart-summary__shippay-line:last-child {
  margin-bottom: 0px;
}
.cart-summary__shippay-line .last {
  text-align: right;
}

.cart-restore {
  margin-bottom: 4.5rem;
}
.cart-restore p {
  text-align: center;
}
.cart-restore form {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.cart-restore form .btn {
  margin: 5px;
}
@media (max-width: 450px) {
  .cart-restore form .btn {
    font-size: 1.5rem;
  }
}

.cart-delivery {
  overflow: hidden;
  margin-bottom: 3rem;
}
.cart-delivery__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
}
@media (max-width: 1024px) {
  .cart-delivery__container {
    flex-wrap: wrap;
  }
}
.cart-delivery__column {
  width: 33.333%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .cart-delivery__column {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .cart-delivery__column {
    width: 100%;
  }
}
.cart-delivery__column--sticky {
  position: sticky;
  top: 20px;
}
@media (max-width: 1024px) {
  .cart-delivery__column--sticky {
    width: 100%;
  }
}
.cart-delivery__column h2:first-child {
  margin-top: 0px;
}
.cart-delivery__nodelivery {
  width: 66.666%;
  padding: 15px;
  box-sizing: border-box;
}
.cart-delivery__info {
  display: inline-block;
  margin-left: 28px;
}
.cart-delivery__additional-container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
}
@media (max-width: 768px) {
  .cart-delivery__additional-container {
    flex-wrap: wrap;
  }
}
.cart-delivery__additional-container .site-form__line label {
  font-size: 3rem;
  display: block;
  margin-bottom: 17px;
}
.cart-delivery__additional-input {
  margin-top: 10px;
  position: relative;
}
.cart-delivery__additional-input p {
  margin-top: 5px;
  font-style: italic;
}
.cart-delivery__additional-note {
  width: 66.666%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .cart-delivery__additional-note {
    width: 55%;
  }
}
@media (max-width: 768px) {
  .cart-delivery__additional-note {
    width: 100%;
  }
}
.cart-delivery__additional-formality {
  width: 33.333%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .cart-delivery__additional-formality {
    width: 45%;
  }
}
@media (max-width: 768px) {
  .cart-delivery__additional-formality {
    width: 100%;
  }
}
.cart-delivery__additional-formality .site-form__line--pack {
  padding-bottom: 0px;
}

.cart-login__container {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
}
.cart-login__container .btn {
  margin: 10px 0px;
  margin-right: 15px;
}
.cart-login__container p {
  text-align: center;
}

.finalstep__card {
  border: 1px solid #EFF1F3;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.finalstep__card-line {
  border-top: 1px solid #EFF1F3;
  display: block;
  margin-top: 10px;
}
.finalstep__card p {
  font-size: 18px;
}

@media (min-width: 768px) {
  .contact__container {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
  }
}
@media (min-width: 768px) {
  .contact__info {
    width: 35%;
  }
}
.contact__info h1 {
  margin-top: 0px;
}
.contact__info-address {
  font-size: 2.2rem;
}
.contact__info-company {
  font-size: 1.8rem;
}
.contact__info-contacts {
  font-size: 1.8rem;
  line-height: 2;
}
.contact__info-contacts .icon::before {
  color: #151617;
  font-size: 1.8rem;
  margin-right: 8px;
  display: inline-block;
}
.contact__info-contacts .icon--blue::before {
  color: #0080FF;
}
.contact__info-contacts a {
  color: #151617;
  text-decoration: none;
}
.contact__info-opening {
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .contact__map {
    width: 65%;
  }
}

.customer-table {
  border: 0px;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
.customer-table__head th {
  font-weight: 400;
  background-color: #EFF1F3;
  text-align: left;
  font-size: 1.8rem;
  padding: 10px;
  border: 0px;
}
.customer-table__head th.name {
  width: 25%;
}
.customer-table__head th.download {
  width: 10%;
}
@media (max-width: 576px) {
  .customer-table__head {
    display: none;
  }
}
@media (max-width: 576px) {
  .customer-table tr:nth-child(2n+1) td {
    background-color: #EFF1F3;
  }
}
@media (min-width: 576px) {
  .customer-table tr:hover > td {
    background-color: rgba(243, 80, 46, 0.11) !important;
  }
}
@media (max-width: 576px) {
  .customer-table tr.odd > td {
    background-color: inherit;
  }
}
@media (max-width: 576px) {
  .customer-table tr.even > td {
    background-color: #EFF1F3;
  }
}
.customer-table td {
  padding: 10px;
}
@media (max-width: 576px) {
  .customer-table td {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
  }
}
@media (max-width: 576px) {
  .customer-table td:first-child {
    display: block;
    cursor: pointer;
    position: relative;
  }
}
@media (max-width: 576px) {
  .customer-table td:first-child:after {
    content: "\e915";
    font-family: "kmotor";
    font-size: 2rem;
    color: #f3502e;
    position: absolute;
    right: 10px;
    top: 8px;
  }
}
@media (max-width: 576px) {
  .customer-table td.header-open {
    background-color: rgba(243, 80, 46, 0.2) !important;
  }
}
@media (max-width: 576px) {
  .customer-table td.header-open:after {
    content: "\e914";
  }
}
@media (max-width: 576px) {
  .customer-table td::before {
    content: attr(data-before);
  }
}
@media (max-width: 576px) {
  .customer-table td.td-open {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
  }
}
.customer-table td .action {
  text-decoration: none;
}
.customer-table td .icon {
  margin: 5px;
}
@media (max-width: 576px) {
  .customer-table__higlightrow td:first-child {
    border-top: 2px solid #f3502e;
    color: #f3502e;
  }
}
@media (min-width: 576px) {
  .customer-table__higlightrow td {
    background: #f3502e !important;
    color: white;
  }
}
@media (min-width: 576px) {
  .customer-table__higlightrow:hover > td {
    color: black;
  }
}
.customer-table__summary td {
  font-weight: 600;
  font-size: 1.8rem;
}
@media (max-width: 576px) {
  .customer-table__summary td {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    cursor: auto;
  }
}
@media (max-width: 576px) {
  .customer-table__summary td:first-child {
    display: none;
  }
}
.customer-table__summary td:first-child:after {
  display: none;
}
@media (max-width: 576px) {
  .customer-table__summary td:nth-child(3) {
    display: none;
  }
}
@media (max-width: 576px) {
  .customer-table__summary td:last-child {
    text-align: right;
  }
}
.customer-table__control {
  margin-bottom: 1rem;
}
.customer-table__control a {
  cursor: pointer;
}
.customer-table__control-right {
  display: none;
  float: right;
}
@media (max-width: 576px) {
  .customer-table__control-right {
    display: block;
  }
}
.customer-table__control-left {
  float: left;
}
.customer-table__control .show-all-items {
  display: none;
}

.customer__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
}
@media (max-width: 1024px) {
  .customer__container {
    flex-wrap: wrap;
  }
}
.customer__column {
  width: 33.333%;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .customer__column:first-child {
    flex: 1 1 100%;
  }
}
@media (max-width: 1024px) {
  .customer__column {
    flex: 0 0 50%;
    width: auto;
  }
}
@media (max-width: 576px) {
  .customer__column {
    flex: 1 1 100%;
  }
}
.customer__column--onecard .customer-card {
  margin-bottom: 0px;
}
.customer__column h2:first-child {
  margin-top: 0px;
}
.customer__column-header {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  flex-wrap: wrap;
}
.customer__column-header h2 {
  margin-bottom: 0;
  margin: 5px 0px;
  margin-right: 15px;
}
.customer__column-header .btn {
  margin: 5px 0px;
}
.customer__heading {
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0;
}
.customer__switch {
  padding: 3rem 0;
  display: inline-block;
  font-size: 2.2rem;
}
.customer__form {
  position: relative;
  width: 680px;
  max-width: 100%;
}
.customer__form .site-form__section-content:before {
  display: none;
}

.get-identity__card {
  max-height: 0px;
  overflow: hidden;
}
.get-identity__card--show {
  max-height: 9999px;
}

.index__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.index__all {
  margin-bottom: 2rem;
  font-size: 2rem;
  display: inline-block;
}
.index__heading {
  flex: 1 1 100%;
  padding: 0px 10px;
}
.index__box {
  flex: 0 0 20%;
  padding: 10px;
  box-sizing: border-box;
}
.index__box-heading {
  margin-top: 0px;
}
@media (max-width: 1024px) {
  .index__box {
    flex: 0 0 25%;
  }
}
@media (max-width: 576px) {
  .index__box {
    flex: 0 0 33.333%;
  }
}
@media (max-width: 450px) {
  .index__box {
    flex: 0 0 50%;
  }
}

.site-main {
  padding-top: 100px;
  padding-bottom: 9.2rem;
}
@media (max-width: 1024px) {
  .site-main {
    padding-bottom: 4rem;
  }
}
@media (max-width: 768px) {
  .site-main {
    padding-top: 170px;
  }
}

.searchtable {
  border: 0px;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  margin: 60px 0px;
}
.searchtable__head th {
  font-weight: 500;
  background-color: #EFF1F3;
  text-align: left;
  font-size: 1.8rem;
  padding: 10px;
  border: 0px;
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .searchtable__head {
    display: none;
  }
}
@media (max-width: 576px) {
  .searchtable tr td {
    background-color: #f3502e;
    margin-top: 1px;
  }
}
@media (min-width: 576px) {
  .searchtable tr:hover > td:not(.searchtable__manufacturer-text) {
    background-color: rgba(243, 80, 46, 0.11) !important;
  }
}
.searchtable td {
  padding: 10px;
}
@media (max-width: 576px) {
  .searchtable td {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
  }
}
@media (max-width: 576px) {
  .searchtable td:first-child:not(.searchtable__manufacturer-text) {
    display: block;
    cursor: pointer;
    position: relative;
  }
}
@media (max-width: 576px) {
  .searchtable td:first-child:not(.searchtable__manufacturer-text) a {
    color: white;
  }
}
@media (max-width: 576px) {
  .searchtable td:first-child:not(.searchtable__manufacturer-text):after {
    content: "\e915";
    font-family: "kmotor";
    font-size: 2rem;
    color: white;
    position: absolute;
    right: 10px;
    top: 8px;
  }
}
@media (max-width: 576px) {
  .searchtable td.header-open:after {
    content: "\e914";
  }
}
@media (max-width: 576px) {
  .searchtable td::before {
    content: attr(data-before);
    text-transform: uppercase;
  }
}
@media (max-width: 576px) {
  .searchtable td.td-open, .searchtable td.td-stillopen {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    background-color: #EFF1F3;
    margin-top: 1px;
  }
}
.searchtable td .action {
  text-decoration: none;
}
.searchtable td .icon {
  margin: 5px;
}
.searchtable__noafter:after {
  display: none;
}
@media (max-width: 576px) {
  .searchtable__higlightrow td:first-child {
    border-top: 2px solid #f3502e;
    color: #f3502e;
  }
}
@media (min-width: 576px) {
  .searchtable__higlightrow td {
    background: #f3502e !important;
    color: white;
  }
}
@media (min-width: 576px) {
  .searchtable__higlightrow:hover > td {
    color: black;
  }
}
.searchtable__summary td {
  font-weight: 600;
  font-size: 1.8rem;
}
@media (max-width: 576px) {
  .searchtable__summary td {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    cursor: auto;
  }
}
@media (max-width: 576px) {
  .searchtable__summary td:first-child {
    display: none;
  }
}
.searchtable__summary td:first-child:after {
  display: none;
}
@media (max-width: 576px) {
  .searchtable__summary td:nth-child(3) {
    display: none;
  }
}
@media (max-width: 576px) {
  .searchtable__summary td:last-child {
    text-align: right;
  }
}
.searchtable__manufacturer-text {
  font-size: 1.8rem;
  font-weight: 600;
  padding-top: 4rem !important;
  background-color: none !important;
}
@media (max-width: 576px) {
  .searchtable__manufacturer-text {
    display: block !important;
    position: relative;
    background-color: white !important;
  }
}
.searchtable__manufacturer-text:after {
  display: none !important;
}
.searchtable__manufacturer:hover > td {
  background-color: white !important;
}

.worldship-control-close {
  display: none;
}

a.worldship-control {
  cursor: pointer;
}

html {
  font-size: 62.5%;
  height: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: "Barlow Condensed", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}
@media (max-width: 1024px) {
  body.fixed {
    overflow: hidden;
    max-height: 100%;
  }
}
@media (max-width: 1024px) {
  body.fixed:after {
    content: "";
    display: block;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 80px;
    background: rgba(255, 255, 255, 0.8);
  }
}

.content {
  max-width: 100%;
  overflow-x: auto;
  min-height: 20vh;
}
.content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.content ul li {
  text-indent: -15px;
  margin-left: 15px;
}
.content ul li::before {
  content: "•";
  color: #f3502e;
  font-size: 1.8rem;
  margin-right: 10px;
}
.content hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.wide-form {
  width: 350px;
  max-width: 100%;
}

.wide-text {
  width: 450px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.wide-text-online {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
}

table {
  width: 100%;
}

h1 {
  font-size: 4rem;
  font-weight: 500;
}
@media (max-width: 1024px) {
  h1 {
    font-size: 3rem;
  }
}

h2 {
  font-size: 3rem;
  font-weight: 500;
}
@media (max-width: 1024px) {
  h2 {
    font-size: 2.6rem;
  }
}
h2.homepage-heading {
  margin-top: 3rem;
  margin-bottom: 0rem;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
}
@media (max-width: 1024px) {
  h3 {
    font-size: 2rem;
  }
}

h4 {
  font-size: 2rem;
  font-weight: 500;
}
@media (max-width: 1024px) {
  h4 {
    font-size: 1.8rem;
  }
}

a {
  color: #f3502e;
}

a.disable {
  pointer-events: none;
  cursor: default;
}

.no-underline {
  text-decoration: none;
}

input {
  font-family: "Barlow Condensed";
}

.container {
  margin: 0 auto;
  width: 1470px;
  padding: 0px 15px;
  max-width: 100%;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.h-maxmobile {
  display: none;
}
@media (max-width: 576px) {
  .h-maxmobile {
    display: block;
  }
}

.read-more-text-short {
  display: block;
}

.read-more-text-full {
  display: none;
}

.btn {
  display: inline-block;
  padding: 13px 18px;
  font-size: 2rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 0px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  font-family: "Barlow Condensed";
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn-primary {
  background: #f3502e;
  color: white;
}
.btn-secondary {
  background: #06B278;
  color: white;
}
.btn-arrowleft {
  position: relative;
  padding-left: 30px;
}
.btn-arrowleft:before {
  content: "\e917";
  font-family: "kmotor";
  vertical-align: -2px;
  -moz-transition: 0.3s left linear;
  -o-transition: 0.3s left linear;
  -webkit-transition: 0.3s left linear;
  transition: 0.3s left linear;
  position: absolute;
  left: 10px;
}
.btn-arrowleft:hover::before {
  left: 8px;
}
.btn-arrowright {
  position: relative;
  padding-right: 30px;
}
.btn-arrowright:after {
  content: "\e911";
  font-family: "kmotor";
  vertical-align: -2px;
  position: absolute;
  -moz-transition: 0.3s right linear;
  -o-transition: 0.3s right linear;
  -webkit-transition: 0.3s right linear;
  transition: 0.3s right linear;
  right: 10px;
}
.btn-arrowright:hover:after {
  right: 8px;
}
.btn-small {
  font-size: 1.4rem;
  padding: 8px 10px;
}
.btn-cookie {
  background: white;
}
.btn-line {
  border: 2px solid white;
  text-decoration: none;
  color: white;
}
.btn-danger {
  background: #e00000;
  color: white;
}
.btn-more {
  border: 1px solid #EFF1F3;
  color: #151617;
  background: white;
  margin: 0 auto;
}
.btn-noactive {
  background: #b2b2b2;
  color: white;
  cursor: default;
}
.btn-disable {
  color: #808080;
  pointer-events: none;
  cursor: default;
}
.btn-gray {
  background: #EFF1F3;
  color: #151617;
  text-align: center;
}
.btn-addtocart {
  position: relative;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn-addtocart::before {
  content: "\e903";
  margin-right: 15px;
  color: white;
  font-family: "kmotor";
}
.btn-braintree {
  background-color: black;
  color: white;
  font-size: 18px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  padding: 10px 20px 10px 60px;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  flex: 0 0 auto;
  max-width: 280px;
}
.btn-braintree:hover {
  background-color: #f3502e;
}
.btn-braintree:hover:after {
  border-left: 2px solid white;
}
.btn-braintree span {
  padding-left: 20px;
}
.btn-braintree:after {
  position: absolute;
  content: "";
  height: 100%;
  border-left: 2px solid #3E3E3E;
  left: 60px;
  top: 0px;
}
.btn-braintree::before {
  content: "";
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:cc='http://creativecommons.org/ns%23' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:sodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd' xmlns:inkscape='http://www.inkscape.org/namespaces/inkscape' height='48' viewBox='0 0 48 48' width='48' version='1.1' id='svg6' sodipodi:docname='352523_lock_icon.svg' inkscape:version='0.92.2 (5c3e80d, 2017-08-06)'%3E%3Cmetadata id='metadata12'%3E%3Crdf:RDF%3E%3Ccc:Work rdf:about=''%3E%3Cdc:format%3Eimage/svg+xml%3C/dc:format%3E%3Cdc:type rdf:resource='http://purl.org/dc/dcmitype/StillImage' /%3E%3C/cc:Work%3E%3C/rdf:RDF%3E%3C/metadata%3E%3Cdefs id='defs10' /%3E%3Csodipodi:namedview pagecolor='%23ffffff' bordercolor='%23666666' borderopacity='1' objecttolerance='10' gridtolerance='10' guidetolerance='10' inkscape:pageopacity='0' inkscape:pageshadow='2' inkscape:window-width='1920' inkscape:window-height='1013' id='namedview8' showgrid='false' inkscape:zoom='4.9166667' inkscape:cx='11.898305' inkscape:cy='24' inkscape:window-x='-9' inkscape:window-y='-9' inkscape:window-maximized='1' inkscape:current-layer='svg6' /%3E%3Cpath d='M0 0h48v48h-48z' fill='none' id='path2' /%3E%3Cpath d='M36 16h-2v-4c0-5.52-4.48-10-10-10s-10 4.48-10 10v4h-2c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4v-20c0-2.21-1.79-4-4-4zm-12 18c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm6.2-18h-12.4v-4c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4z' id='path4' style='fill:%23ffffff;fill-opacity:1' /%3E%3C/svg%3E%0A");
  position: absolute;
  left: 20px;
}
.btn-braintree img {
  max-height: 30px;
  width: auto;
  margin-left: 50px;
}
.btn-klarna {
  background-color: black;
  color: white;
  font-size: 18px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  padding: 10px 20px 10px 60px;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  flex: 0 0 auto;
  max-width: 280px;
}
.btn-klarna:hover {
  background-color: #333333;
}
.btn-klarna:hover:after {
  border-left: 2px solid white;
}
.btn-klarna span {
  padding-left: 20px;
}
.btn-klarna:after {
  position: absolute;
  content: "";
  height: 100%;
  border-left: 2px solid white;
  left: 65px;
  top: 0px;
}
.btn-klarna::before {
  content: "";
  width: 45px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg");
  position: absolute;
  left: 10px;
}

.badge {
  background: #808080;
  color: white;
  font-size: 1.6rem;
  padding: 8px 10px;
  padding-bottom: 10px;
  line-height: 1;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  display: inline-block;
  margin: 5px;
}

.badge-gold {
  background-color: #d89b65;
}

.badge-primary {
  background-color: #f3502e;
}

.badge-warning {
  background-color: #F8CE29;
}

.badge-info {
  background-color: #0082D9;
}

.badge-danger {
  background-color: #e00000;
}

.badge-success {
  background-color: #06B278;
}

.badge-third {
  background-color: #F3502E;
}

.badge-table {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 0;
  text-align: center;
}

.badge-table {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 0;
  text-align: center;
}

.js-select, .js-multiselect {
  display: none;
}

.site-form--login {
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
}
.site-form--register {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.site-form__line-hp {
  tab-index: -1 !important;
  position: absolute !important;
  left: -21234px !important;
}
.site-form__line {
  margin-bottom: 20px;
}
.site-form__line .form-text {
  font-size: 1.4rem;
  margin-top: 5px;
}
.site-form__line .text-danger {
  color: darkred;
}
.site-form__line--pack {
  position: relative;
  padding-bottom: 2rem;
  margin: 0;
}
.site-form__line--pack .col-form-label {
  position: absolute;
  color: #808080;
  background: white;
  position: absolute;
  top: 12px;
  left: 15px;
  font-size: 1.6rem;
  transition: background 0.2s, color 0.2s, top 0.2s, font-size 0.2s, left 0.2s;
  z-index: 1;
  max-width: 100%;
  white-space: nowrap;
  margin-bottom: 0 !important;
}
.site-form__line--pack .col-form-label--select {
  font-size: 1.4rem;
  top: -10px;
}
.site-form__line--pack .form-control {
  border: 1px solid #EFF1F3;
  width: 100%;
  padding: 10px 15px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  font-size: 1.8rem;
  resize: vertical;
  box-sizing: border-box;
  font-family: "Barlow Condensed";
}
.site-form__line--pack .form-control:focus {
  outline: 0px;
}
.site-form__line--pack .form-control::placeholder {
  opacity: 0;
}
.site-form__line--pack.js-focused .col-form-label {
  font-size: 1.4rem;
  top: -10px;
}
.site-form__line--pack.js-focused .form-control::placeholder {
  opacity: 0.4;
}
.site-form__line-find {
  color: white;
  background: #f3502e;
  right: 5px;
  top: 5px;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 5000;
  cursor: pointer;
}
.site-form__line-find:hover {
  opacity: 0.6;
}
.site-form__line-clear {
  color: black;
  background: #EFF1F3;
  right: 0px;
  top: -50px;
  position: absolute;
  font-family: "Barlow Condensed", sans-serif;
  color: #f3502e;
  text-decoration: underline;
  border: 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 5000;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .site-form__line-clear {
    background-color: white;
  }
}
.site-form__line-clear:hover {
  color: black;
}
.site-form__section {
  padding-bottom: 20px;
}
.site-form__section > .site-form__section-content:before {
  border-top: 0px !important;
}
.site-form__section-heading {
  font-size: 2rem;
  padding-right: 15px;
  width: 30%;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  margin-top: 0px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .site-form__section-heading {
    width: 100%;
  }
}
.site-form__section-content {
  width: 69%;
  display: inline-block;
  padding-top: 20px;
}
@media (max-width: 768px) {
  .site-form__section-content {
    width: 100%;
  }
}
.site-form__section-content::before {
  content: "";
  border-top: 1px solid #EFF1F3;
  width: 100%;
  position: absolute;
  left: 0px;
  background: white;
  height: 20px;
  display: block;
  margin-top: -20px;
}
.site-form__section-content--inline .form-check {
  display: inline-block;
}
.site-form__input {
  background: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #151617;
  font-size: 1.6rem;
  font-weight: 400;
  border: 0px;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.site-form__input::placeholder {
  color: #808080;
  opacity: 1;
}
.site-form__input:focus {
  outline: 0px !important;
}

.site-check {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.site-check:hover {
  text-decoration: none !important;
  cursor: pointer;
}
.site-check:hover .site-check__checkmark {
  border-color: #b2b2b2;
}
.site-check input {
  position: absolute;
  opacity: 0;
}
.site-check input:checked ~ .site-check__checkmark:after {
  display: block;
}
.site-check input[disabled] ~ .site-check__checkmark {
  opacity: 0.4;
  background: #D8D8D8;
}
.site-check input:checked[disabled] ~ .site-check__checkmark:after {
  border: solid #151617;
  border-width: 0 2px 2px 0;
}
.site-check--partly .site-check__checkmark:after {
  display: block !important;
  border: 0px;
  border-top: 2px solid #f3502e;
  color: #f3502e;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 8px;
  top: 7px;
  left: 4px;
}
.site-check__text {
  display: inline-block;
  font-size: 1.6rem;
  margin-left: 8px;
  cursor: pointer;
  margin-top: -2px;
}
.site-check__text a {
  color: #151617;
  text-decoration: underline;
}
.site-check__text a:hover {
  color: #f3502e;
}
.site-check__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #D8D8D8;
}
.site-check__checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 3px;
  height: 10px;
  border: solid #f3502e;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.like-select {
  margin: 0;
  padding: 0;
  background: #151617;
  cursor: pointer;
  position: relative;
}
.like-select__item {
  list-style: none;
  line-height: 1;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
.like-select__item a {
  color: white;
  text-decoration: none;
}
.like-select > .like-select__item:after {
  content: "\e915";
  margin-left: 5px;
  font-family: "kmotor";
}
.like-select__submenu {
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  margin: 0;
  padding: 0;
}
.like-select__submenu--active {
  max-height: 9999px;
  top: 100%;
  left: 0px;
  z-index: 25000;
  background: #151617;
}

.site-radio {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.site-radio:hover {
  text-decoration: none !important;
  cursor: pointer;
}
.site-radio:hover .site-radio__checkmark {
  border-color: #b2b2b2;
}
.site-radio input {
  position: absolute;
  opacity: 0;
}
.site-radio input:checked ~ .site-radio__checkmark:after {
  display: block;
}
.site-radio input[disabled] ~ .site-radio__checkmark {
  opacity: 0.4;
  background: #D8D8D8;
}
.site-radio input:checked[disabled] ~ .site-radio__checkmark:after {
  background: #151617;
}
.site-radio__text {
  display: inline-block;
  font-size: 1.6rem;
  margin-left: 8px;
  cursor: pointer;
  margin-top: -2px;
}
.site-radio__text a {
  color: #151617;
  text-decoration: underline;
}
.site-radio__text a:hover {
  color: #f3502e;
}
.site-radio__text--shippay {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.site-radio__text--shippay span:last-child {
  text-align: right;
}
.site-radio__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #D8D8D8;
}
.site-radio__checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  background: #f3502e;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.pagination {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
}
.pagination--records {
  justify-content: flex-start;
  padding-left: 0px;
  margin-left: 0px;
  align-items: center;
}
.pagination__item {
  list-style: none;
  font-size: 2rem;
  padding: 2px 5px;
  margin: 5px;
  height: 28px;
  width: 28px;
  box-sizing: border-box;
  text-align: center;
}
.pagination__item--text {
  font-size: 1.6rem;
  width: auto;
  height: auto;
}
.pagination__item a {
  color: #151617;
  text-decoration: none;
}
.pagination__item--active {
  background: #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.incart {
  position: absolute;
  background: #151617;
  color: white;
  padding: 19px 25px 30px 25px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 369px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  right: -40px;
  margin-top: 20px;
  z-index: 2;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -moz-transition: 0.8s display linear;
  -o-transition: 0.8s display linear;
  -webkit-transition: 0.8s display linear;
  transition: 0.8s display linear;
}
@media (max-width: 576px) {
  .incart {
    max-width: calc(100vw - 30px);
    right: auto;
    left: 15px;
    position: fixed;
    top: 25%;
    width: calc(100vw - 30px);
  }
}
.incart--close {
  display: none;
}
.incart__close {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 1.8rem;
  cursor: pointer;
}
.incart__heading {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  display: inline-block;
}
.incart__heading svg {
  font-size: 1.8rem;
  vertical-align: -2px;
  margin-right: 5px;
}
.incart__item {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex: 1 1 100%;
}
.incart__item-pic {
  flex: 0 0 20%;
}
.incart__item-pic img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.incart__item-text {
  flex: 1 1 auto;
  padding-left: 8px;
}
.incart__item-heading {
  color: white;
  font-size: 1.8rem;
}
.incart__item-properties {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
}
.incart__item-properties span {
  margin: 5px;
}
.incart__buttons {
  margin-top: 2.2rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.incart__buttons .btn {
  margin-left: 15px;
}
.incart__buttons span {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
  cursor: pointer;
}

.itemscart {
  position: absolute;
  background: white;
  -webkit-box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  -moz-box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  box-shadow: -1px -1px 10px 4px rgba(150, 150, 150, 0.4);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 250px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  right: -40px;
  margin-top: 20px;
  z-index: 2;
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  -moz-transition: 0.8s display linear;
  -o-transition: 0.8s display linear;
  -webkit-transition: 0.8s display linear;
  transition: 0.8s display linear;
  display: none;
}
@media (max-width: 576px) {
  .itemscart {
    display: none;
  }
}
.itemscart::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -15px;
  left: 68%;
}
.itemscart__link {
  color: #151617;
  text-decoration: none;
  display: inline-block;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
}
.itemscart__close {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 1.8rem;
  cursor: pointer;
}
.itemscart__item {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex: 1 1 100%;
}
.itemscart__item-pic {
  flex: 0 0 20%;
}
.itemscart__item-pic img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.itemscart__item-text {
  flex: 1 1 auto;
  padding-left: 8px;
}
.itemscart__item-heading {
  color: white;
  font-size: 1.8rem;
}
.itemscart__item-properties {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
}
.itemscart__item-properties span {
  margin: 5px;
}
.itemscart__buttons {
  margin-top: 2.2rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemscart__buttons .btn {
  margin-left: 15px;
}
.itemscart__buttons span {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
  cursor: pointer;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 7, 2022 */
@font-face {
  font-family: "Barlow Condensed";
  src: url("../webfont/barlowcondensed-italic-webfont.woff2") format("woff2"), url("../webfont/barlowcondensed-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Barlow Condensed";
  src: url("../webfont/barlowcondensed-regular-webfont.woff2") format("woff2"), url("../webfont/barlowcondensed-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Barlow Condensed";
  src: url("../webfont/barlowcondensed-medium-webfont.woff2") format("woff2"), url("../webfont/barlowcondensed-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Barlow Condensed";
  src: url("../webfont/barlowcondensed-mediumitalic-webfont.woff2") format("woff2"), url("../webfont/barlowcondensed-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Barlow Condensed";
  src: url("../webfont/barlowcondensed-semibold-webfont.woff2") format("woff2"), url("../webfont/barlowcondensed-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Barlow Condensed";
  src: url("../webfont/barlowcondensed-semibolditalic-webfont.woff2") format("woff2"), url("../webfont/barlowcondensed-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
.product-banner {
  background: #f3502e;
  color: white;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 4rem;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
}
.product-banner__icon {
  font-size: 5rem;
}
.product-banner__heading {
  font-size: 2rem;
  margin-bottom: 15px;
  margin-top: 30px;
  text-align: center;
}
.product-banner__text {
  font-size: 1.8rem;
  text-align: center;
}
.product-banner__link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.multilevel ol {
  counter-reset: item;
  padding-left: 0;
}
.multilevel .a li:before {
  content: counter(item, lower-alpha) ")";
}
.multilevel li {
  display: block;
  position: relative;
  padding-left: 15px;
}
.multilevel li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
  font-weight: 600;
  position: absolute;
  left: 0;
}
.multilevel > ol > li {
  padding-left: 30px;
}
.multilevel > ol > li::before {
  font-size: 3rem;
}
@media (max-width: 1024px) {
  .multilevel > ol > li::before {
    font-size: 2.6rem;
  }
}
.multilevel > ol > li > ol > li {
  padding-left: 40px;
}

.advantages {
  padding-top: 5.2rem;
}
@media (max-width: 1024px) {
  .advantages {
    padding-top: 4rem;
  }
}
.advantages__heading {
  text-align: center;
  margin-bottom: 7.6rem;
}
@media (max-width: 1024px) {
  .advantages__heading {
    margin-bottom: 3rem;
    margin-top: 0;
  }
}
.advantages__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 -13px;
  flex-wrap: wrap;
}
.advantages__item {
  width: 25%;
  padding: 18px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .advantages__item {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .advantages__item {
    width: 100%;
  }
}
.advantages__item .icon {
  margin-right: 30px;
  font-size: 5.2rem;
  color: #f3502e;
  flex: 0 0 42px;
}
.advantages__item .icon:before {
  color: #f3502e;
}
.advantages__item-heading {
  font-size: 2.2rem;
  margin-bottom: 3px;
  margin-top: 0;
}
.advantages__item-text {
  margin: 0;
}

.news-carousel {
  margin: 0 -1.5rem;
  overflow: hidden;
  padding-top: 25px;
}
.news-carousel__container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.invoice__bottom {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
  margin-top: 4rem;
}
@media (max-width: 576px) {
  .invoice__bottom {
    flex-wrap: wrap;
  }
}
.invoice__bottom-left, .invoice__bottom-right {
  width: 50%;
  padding: 0px 15px;
}
@media (max-width: 576px) {
  .invoice__bottom-left, .invoice__bottom-right {
    width: 100%;
  }
}
.invoice__bottom-left {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 0px;
}
@media (max-width: 1024px) {
  .invoice__bottom-left {
    flex-wrap: wrap;
  }
}
.invoice__bottom h2 {
  margin-top: 0px;
}
.invoice__bottom .customer-card {
  margin-bottom: 3rem;
}
.invoice__info {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .invoice__info {
    width: 100%;
  }
}
.invoice__shipping {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .invoice__shipping {
    width: 100%;
  }
}

.order__bottom {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
  margin-top: 4rem;
}
@media (max-width: 576px) {
  .order__bottom {
    flex-wrap: wrap;
  }
}
.order__bottom-left, .order__bottom-right {
  width: 50%;
  padding: 0px 15px;
}
@media (max-width: 576px) {
  .order__bottom-left, .order__bottom-right {
    width: 100%;
  }
}
.order__bottom-left {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 0px;
}
@media (max-width: 1024px) {
  .order__bottom-left {
    flex-wrap: wrap;
  }
}
.order__bottom h2 {
  margin-top: 0px;
}
.order__bottom .customer-card {
  margin-bottom: 3rem;
}
.order__bottom .customer-card input {
  max-width: 100%;
}
.order__info {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .order__info {
    width: 100%;
  }
}
.order__shipping {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .order__shipping {
    width: 100%;
  }
}

.order-notes {
  border: 1px solid #EFF1F3;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-bottom: 15px;
}
.order-notes__item {
  padding: 15px;
  padding-bottom: 0px;
}
.order-notes__item-head {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
}
.order-notes__item-avatar {
  background: #151617;
  color: white;
  width: 50px;
  height: 50px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.order-notes__item-avatar svg {
  color: white;
  font-size: 2.5rem;
}
.order-notes__item-avatar--ks {
  color: white;
  background: #f3502e;
}
.order-notes__item-avatar--ks span::before {
  color: white;
  font-size: 2.2rem;
}
.order-notes__item-person {
  font-weight: 600;
  font-size: 2.2rem;
}
.order-notes__item-text {
  font-size: 1.8rem;
}
.order-notes__item--reaction {
  border-left: 2px solid #EFF1F3;
  margin-left: 40px;
  margin-top: 15px;
}

.order-pay-qr-box {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  gap: 3rem;
  align-content: flex-end;
  flex-wrap: wrap;
}

.payment-page .order-pay-qr-box {
  justify-content: center;
}

.quote__bottom {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  margin: 0 -15px;
  margin-top: 4rem;
}
@media (max-width: 576px) {
  .quote__bottom {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.quote__bottom-left, .quote__bottom-right {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 576px) {
  .quote__bottom-left, .quote__bottom-right {
    width: 100%;
  }
}
.quote__bottom-left {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  padding: 0px;
}
@media (max-width: 1024px) {
  .quote__bottom-left {
    flex-wrap: wrap;
  }
}
.quote__bottom h2 {
  margin-top: 0px;
}
.quote__bottom .customer-card {
  margin-bottom: 3rem;
}
.quote__bottom .customer-card input {
  max-width: 100%;
}
.quote__info {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .quote__info {
    width: 100%;
  }
}
.quote__shipping {
  width: 50%;
  padding: 0px 15px;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .quote__shipping {
    width: 100%;
  }
}
.quote__buttons {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 576px) {
  .quote__buttons {
    margin-bottom: 4rem;
  }
}
.quote__buttons a {
  color: #151617;
  text-decoration: underline;
}

.manufacturer-page__grid {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.manufacturer-page__item {
  width: 20%;
  padding: 20px 10px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
@media (max-width: 768px) {
  .manufacturer-page__item {
    width: 33.333%;
  }
}
@media (max-width: 450px) {
  .manufacturer-page__item {
    width: 50%;
  }
}
.manufacturer-page__item-link {
  display: inline-block;
  height: 100%;
}
.manufacturer-page__item-content {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.manufacturer-page__item img {
  max-width: 100%;
  filter: grayscale(4);
  -webkit-filter: grayscale(4);
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}
.manufacturer-page__item img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}
.manufacturer-page__item span {
  margin-top: auto;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 44px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #EFF1F3;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 2051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #EFF1F3;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: grey;
  line-height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: 700;
  height: 42px;
  margin-right: 20px;
  padding-right: 0;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: grey;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 42px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow:after {
  content: "\e915";
  font-family: "kmotor";
  color: #151617;
  line-height: 42px;
  font-size: 18px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: "\e914";
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #EFF1F3;
  cursor: default;
  opacity: 0.6;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: 700;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #EFF1F3;
  font-size: 16px;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 44px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: 700;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide:focus {
  border: 0px;
  outline: 0px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick-carousel/slick/fonts/slick.eot");
  src: url("../../fonts/slick-carousel/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick-carousel/slick/fonts/slick.woff") format("woff"), url("../../fonts/slick-carousel/slick/fonts/slick.ttf") format("truetype"), url("../../fonts/slick-carousel/slick/fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Barlow Condensed";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slick-arrow {
  position: absolute;
  left: auto;
  top: 10px;
  right: 0px;
  z-index: 21;
}

.slick-prev {
  right: 40px;
}
@media only screen and (max-width: 1500px) {
  .slick-prev {
    right: 55px;
  }
}
.slick-prev:before {
  color: black;
}

.slick-next {
  right: 0px;
}
@media only screen and (max-width: 1500px) {
  .slick-next {
    right: 15px;
  }
}
.slick-next:before {
  color: black;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.gallery-selectors {
  display: flex;
  justify-content: center;
  column-gap: 3px;
  align-items: flex-end;
  margin-bottom: 5px;
}

.gallery-selector-360-container {
  position: relative;
  top: 0;
  left: 0;
}

.gallery-selector-360-image {
  position: relative;
  top: 0;
  left: 0;
  opacity: 0.6;
  background-color: #ffffff;
}

.gallery-selector-360-pictogram {
  position: absolute;
  left: 0px;
  bottom: 18px;
}

img.gallery-selector-360-pictogram {
  border: none !important;
}

.site-skeleton {
  width: 100%;
  display: block;
  position: relative;
}
.site-skeleton:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: "";
}
.site-skeleton--select {
  height: 54px;
  line-height: 54px;
  background-color: #D8D8D8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.site-skeleton--select_low {
  height: 40px;
  line-height: 40px;
  background-color: #D8D8D8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.site-skeleton-text {
  padding-left: 10px;
}

.site-skeleton-device {
  padding-left: 50px;
}